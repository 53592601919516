export const firebaseConfig = {
  apiKey: "AIzaSyBHCHYtxckz7VOz4bDPEpxR7v8s_QttnPE",
  authDomain: "tejarat-family.firebaseapp.com",
  projectId: "tejarat-family",
  storageBucket: "tejarat-family.appspot.com",
  messagingSenderId: "783161509031",
  appId: "1:783161509031:web:6381998ede9f6b96278782",
  measurementId: "G-2VMZZZSJY5"
};

export const vapidKey = "BBu9Shcj2SDem20oKLPZlB731ig9CWQ_ThgkodkuhgRGPouH-X64ODMdQEn4_XuzFHfgeCKyt76B-LtebzX3vqI";