import globalStorage from 'lib/globalStorage';
import reducers from 'features';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import globalStorageItems from 'utility/constants/globalStorageItems';
import { configureStore } from '@reduxjs/toolkit';

function preloadState() {
    if(globalStorage.getItem(globalStorageItems.USER_TOKEN)){
        return { 
            user: { 
                isLogin: true, 
                userInfo: globalStorage.getItem(globalStorageItems.USER_INFO) 
            } 
        }
    }
    globalStorage.clear();
} 

const store = configureStore({
    reducer: reducers,
    preloadedState: preloadState()
})


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const { dispatch: globalDispatch } = store;
export default store;