import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import { CancelToken } from "axios"
import { mapkeys } from "lib"
import SOAP_request from "lib/http-request/SOAP-request"
import { RootState } from "store"
import { baseGroupOrContent } from "utility/constants/baseGroupOrContent"
import { NewssocialMediasIds } from "utility/constants/newsSocialMedias"

export interface INewsDetails {
    BinaryFileName1: string|null
    BinaryFileName2: string|null
    BinaryFileName3: string|null
    ConfirmedCommentCount: number
    Context: string
    Context2: string
    CreatedBy: string
    CreatedById: null|string
    CreatedDate: string
    CreatedDateStr: string
    FileName1: string
    FileName2: string
    FileName3: string
    FileName4: string
    FileUrl: string
    Hashtags: string
    Head: string
    IsActive: true
    IsLiked: false
    IsPublicBase: true
    IsSeen: false
    IsShareable: true
    IsSpecial: true
    LanguageBaseId: number
    Lat: string
    Lon: string
    NegativeScore: number
    NewsBreif: string
    NewsId: string
    NewsLanguage: number
    NewsTypeBaseId: number
    NewsTypeBaseTitle: string
    NewsViewCount: number
    PositiveScore: number
    Title: string
    TitleFileName1: string
    TitleFileName2: string
    TitleFileName3: string
    FileUrlTitle: string
    TotalCommentCount: number
    UnConfirmedCommentCount: number
    UpdatedBy: number
    UpdatedDate: string
    UpdatedDateStr: string
    Version: string
    AllNewsCount: number
}

export interface ICommentDetails { 
    CommentId: string
    ObjectId: string
    ReferenceId: string
    PhotoName: string
    Comment: string 
    FullName: string 
    UserId: string
    FileName: string
    ActionDate: string
    IsConfirmed: boolean
}

export interface IGalleryDetails { 
    Id: string,
    Title: string
    FileName: string
}

export interface ISocialMediaDetails { 
    NewsId: string
    SocialMediaId: NewssocialMediasIds
    SocialMediaTitle: string
    Url: string
}

export interface IBaseDetails {
    Id: string,
    ParentId: string,
    Title: string,
    Description: string,
    Image: string,
    OrderNo: string,
    BaseCount: string
}

export interface IBasses {
    superBases: IBaseDetails[]
    subBases: IBaseDetails[]
}

export interface IFetchNewsArgs {
    LanguageBaseId?: number,
    Title?: string,
    NewsTypeBaseId: string|baseGroupOrContent,
    IsSpecial?: boolean,
    OrderBy?: string,
    FromRecord?: number,
    PageSize?: number,
    Direction?: 'Desc'|'Asce',
    OnlyLikedString?: string,
    SenderId?: string
    cancelToken?: CancelToken
}

export interface INewsSliceState {
    bases: { [parentId: string]: IBasses }
    basesNews: { [baseId: string]: { [newsId: string]: INewsDetails }|null }
    newsDetails: { [newsId: string]: INewsDetails }
    newsComments: { [newsId: string]: ICommentDetails[] }
    newsGallery: { [newsId: string]: IGalleryDetails[] }
    newsSocialMedias: { [newsId: string]: ISocialMediaDetails[] }
}

export const fetchBases = createAsyncThunk(
    'news/fetchBases',
    async function(parentId: string) {

        const { data: { IdTitles: superBases } } = await SOAP_request({
            soapAction: 'GetSuperBases',
            method: 'POST',
            data: {
                langId: 11,
                order: 'OrderNo',
                parentId
            }
        });
        const { data: { IdTitles: subBases } } = await SOAP_request({
            soapAction: 'GetSubBases',
            method: 'POST',
            data: {
                LanguageBaseId: 11,
                OrderBy: 'OrderNo',
                parentId,
                SuperBaseId: parentId
            }
        });

        return {
            parentId,
            superBases,
            subBases
        };
    }
)

export const fetchBasesNews = createAsyncThunk(
    'news/basesNews',
    async function({
        LanguageBaseId= 11,
        Title= '',
        NewsTypeBaseId,
        IsSpecial= false,
        OrderBy= 'createddate',
        FromRecord= 0,
        PageSize= 10,
        Direction= 'Desc',
        OnlyLikedString= '',
        SenderId= '',
        cancelToken
    }: IFetchNewsArgs) {
        const { data: { VNews: news } } = await SOAP_request({
            cancelToken,
            soapAction: 'GetNews',
            method: 'POST',
            data: {
                LanguageBaseId,
                Title,
                NewsTypeBaseId,
                IsSpecial,
                OrderBy,
                FromRecord,
                PageSize,
                Direction,
                OnlyLikedString,
                SenderId
            }
        });

        return {
            news,
            baseId: NewsTypeBaseId
        };
    }
);

const sortByDate = (a: INewsDetails, b: INewsDetails) => (new Date(a['CreatedDateStr']) < new Date(b['CreatedDateStr'])) 
? 1 
: ((new Date(b['CreatedDateStr']) < new Date(a['CreatedDateStr'])) ? -1 : 0)

export const newsBasesSelector = (baseId: string)=> (state: RootState)=> (
    state.news.basesNews[baseId] && (
        Object.values(state.news.basesNews[baseId]!).sort(sortByDate)
    )
)

export const fetchBasesNewsDetails = createAsyncThunk(
    'news/newsDetails',
    async function(NewsId: string) {
        const { data: { VNews: [NewsDetails] } } = await SOAP_request({
            soapAction: 'GetNewsInfo',
            method: 'POST',
            data: {
                LanguageBaseId: 11,
                NewsId
            }
        });

        return { NewsId, NewsDetails};
    }
)

export const fetchBasesNewsComments = createAsyncThunk(
    'news/newsComments',
    async function(newsId: string) {
        const { data: { VComment: newsComments } } = await SOAP_request({
            soapAction: 'GetComments',
            method: 'POST',
            data: {
                LanguageBaseId: 11,
                ObjectId: 5,
                ReferenceId: newsId,
                FromRecord: 0,
                PageSize: 200,
            }
        });

        return { newsId, newsComments };
    }
)

export const fetchBasesNewsGallery = createAsyncThunk(
    'news/newsGallery',
    async function(NewsId: string) {
        const { data: { NewsGallery } } = await SOAP_request({
            soapAction: 'GetNewsGallery',
            method: 'POST',
            data: { NewsId }
        });
        return { NewsId, NewsGallery };
    }
)

export const fetchBasesNewsSocialMedias = createAsyncThunk(
    'news/newsSocialMedias',
    async function(NewsId: string) {
        const { data: { NewsSocialMedias } } = await SOAP_request({
            soapAction: 'GetNewsSocialMedias',
            method: 'POST',
            data: { NewsId }
        });
        return { NewsId, NewsSocialMedias };
    }
)

export const addNewsScore = createAsyncThunk(
    'news/newsScore',
    async function({ NewsId, Score }: any, ThunkApi) {
        await SOAP_request({
            soapAction: 'AddNewsScore',
            method: 'POST',
            data: { NewsId, Score }
        });

        await ThunkApi.dispatch( fetchBasesNewsDetails(NewsId) );
    }
)

export const resetBasesNews = createAction<string>('news/resetNewsBases');

const initialState: INewsSliceState = {
    bases: {},
    basesNews: {},
    newsDetails: {},
    newsComments: {},
    newsGallery: {},
    newsSocialMedias: {},
}

const newsSlice = createReducer<INewsSliceState>(
    initialState,
    (builder) => {
        builder
            .addCase( fetchBases.fulfilled, ( state, action: PayloadAction<any> )=> {
                const { parentId, superBases, subBases } = action.payload;
                state.bases[parentId] = { superBases, subBases };
            })
            .addCase( fetchBasesNews.fulfilled, ( state, action: PayloadAction<any> )=> {
                const { baseId, news } = action.payload;
                state.basesNews[baseId] = {
                    ...state.basesNews[baseId],
                    ...mapkeys(news, 'NewsId')
                }
            })
            .addCase( fetchBasesNewsDetails.fulfilled, ( state, action: PayloadAction<any> )=> {
                state.newsDetails[action.payload.NewsId] = action.payload.NewsDetails;
            })
            .addCase( fetchBasesNewsComments.fulfilled, ( state, action: PayloadAction<any> )=> {
                state.newsComments[action.payload.newsId] = action.payload.newsComments
            })
            .addCase( fetchBasesNewsGallery.fulfilled, ( state, action: PayloadAction<any> )=> {
                state.newsGallery[action.payload.NewsId] = action.payload.NewsGallery
            })
            .addCase( fetchBasesNewsSocialMedias.fulfilled, ( state, action: PayloadAction<any> )=> {
                state.newsSocialMedias[action.payload.NewsId] = action.payload.NewsSocialMedias
            })
            .addCase( resetBasesNews, ( state, action )=> {
                delete state.basesNews[action.payload];
            })
    }
);

export default newsSlice;