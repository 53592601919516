import { reducer as formReducer } from 'redux-form';
import { combineReducers } from "@reduxjs/toolkit"
import userSlice, { logout } from './user/userSlice';
import alertSlice from './alert/alertSlice';
import dialogSlice from './dialog/dialogSlice';
import navigationStateSlice from './navigation/navigationStateSlice';
import sideBarSlice from './sidebar/sideBarSlice';
import homePageSlice from './home/homePageSlice';
import pagesContentSlice from './pagesContent/pagesContentSlice';
import userMessagesSlice from './messages/userMessagesSlice';
import newsSlice from './news/newsSlice';
import imageLightBoxSlice from './imageLightBox/imageLightBoxSlice';
import photoContestSlice from './photoContest/photoContestSlice';
import healthCentersSlice from './healthCenters/healthCentersSlice';
import surveySlice from './survey/surveySlice';
import QueuingSystemSlice from './queuingSystem/QueuingSystemSlice';
import SearchingBranchesSlice from './searchingBranches/searchingBranchesSlice';


// combined redecucers (Root Reducer)
const AppReducer = combineReducers ({
    form: formReducer,
    user: userSlice,
    navigationState: navigationStateSlice,
    sideBarOpen: sideBarSlice,
    globalAlert: alertSlice,
    globalDialog: dialogSlice,
    homePage: homePageSlice,
    pagesContent: pagesContentSlice,
    userMessages: userMessagesSlice,
    news: newsSlice,
    globalLightBox: imageLightBoxSlice,
    photoContest: photoContestSlice,
    healthCenters: healthCentersSlice,
    survey: surveySlice,
    queuingSystem: QueuingSystemSlice,
    searchingBranches: SearchingBranchesSlice
});

// When the user is logged out store will be cleaned up
const RootReducer: typeof AppReducer = (state , action) => {   
    if(action.type === logout.toString()) {
        return AppReducer(undefined, action);
    }
    return AppReducer(state, action);
};

export default RootReducer;