import { createAsyncThunk, createReducer, PayloadAction, createAction } from "@reduxjs/toolkit"
import REST_APICall from "lib/http-request/REST-request"


export interface ComplexesType {
    complexTimeSheets: any,
    id: number,
    isActive: boolean,
    modifiedDate: string,
    reserves: any,
    title: string,
}

export interface ComplexeTimeSheetType {
    timeSheetId: number,
    start: string,
    end: string,
    capacity: number,
    gender: number,
    genderTitle: string,
}

export interface userFamilyType {
    id: number,
    firstName: string,
    lastName: string,
    gender: number,
    genderTitle: string,
    familyType: string,
    parentPersonalCode: string,
    parentMobileNumber: string
}

export interface ReservationDataType {
    errorId: number,
    errorTitle: string,
    result: {
        paymentCode: string,
        url: string,
        reserveDate: string,
        reserveTime: string,
        price: string
    }|null
}

export const fetchComplex = createAsyncThunk(
    'queuingSystem/fetchComplex',
    async function() {
        const { data: { result: complexes } } = await REST_APICall({
            domain: 'complex',
            url: '/GetAllComplex',
            method: 'GET',
            withCredentials: false
        });        
        return  {complexes} ;
    }
)

export interface TimeSheetBodyReq{
    DateTime:string,
    ComplexId:number
}

export const fetchComplexeTimeSheet = createAsyncThunk(
    'queuingSystem/fetchComplexeTimeSheet',
    async function(reqBody: TimeSheetBodyReq){
        const {data: {result: timeSheetsComp }} = await REST_APICall({
            domain: "complex",
            url:"/GetComplexTimeSheet",
            method: "POST",
            data:reqBody
        });
        const timeSheets = timeSheetsComp.dayTimeSheet
        
        return {timeSheets}
    }
)

export interface userFamilyBodyReq{
    Token:string,
}

export const fetchUsersFamily = createAsyncThunk(
    'queuingSystem/fetchUsersFamily',
    async function(reqBody: userFamilyBodyReq){
        const {data: {result: userFamily }} = await REST_APICall({
            domain: "complex",
            url:"/GetUsersFamily",
            method: "POST",
            data:reqBody
        });
        return {userFamily}
    }
)

export interface ReservationBodyReq{
    TimeSheetId : string,
	DateTime : string,
	Token : string,
	familyId : number
}

export const fetchReservation = createAsyncThunk(
    'queuingSystem/fetchReservation',
    async function(reqBody: ReservationBodyReq){
        const {data: reservationData} = await REST_APICall({
            domain: "complex",
            url:"/Reserve",
            method: "POST",
            data:reqBody
        });
        return {reservationData}
    }
)

export const changeSelectedDate = createAction<string>("queuingSystem/changeSelectedDate")
export const changeSelectedTimeSheetId = createAction<number>("queuingSystem/changeSelectedTimeSheetId")
export interface QueuingSystemState {
    complexes: ComplexesType[],
    timeSheets: ComplexeTimeSheetType[],
    userFamily: userFamilyType[],
    reservationData: ReservationDataType,
    selectedDate: string,
    selectedTimeSheetId: number
}

const initialState = {
    complexes: [],
    timeSheets: [],
    userFamily: [],
    reservationData: {
        errorId: 1,
        errorTitle: "",
        result:{
            paymentCode: "",
            url: "",
            reserveDate: "",
            reserveTime: "",
            price: ""
        }
    },
    selectedDate: "",
    selectedTimeSheetId: 0,
}

const QueuingSystemSlice = createReducer<QueuingSystemState>(
    initialState,
    (builder)=>{
        builder
        .addCase(fetchComplex.fulfilled, (state, action: PayloadAction<any>)=>{
            const{complexes} = action.payload;
            state.complexes = complexes;
        })
        .addCase(fetchComplexeTimeSheet.fulfilled,  (state, action: PayloadAction<any>)=>{
            const{timeSheets} = action.payload;
            state.timeSheets = timeSheets;
        })
        .addCase(fetchUsersFamily.fulfilled, (state, action: PayloadAction<any>)=>{
            const{userFamily} = action.payload;
            state.userFamily = userFamily;
        })
        .addCase(fetchReservation.fulfilled, (state, action: PayloadAction<any>)=>{
            const{reservationData} = action.payload;
            state.reservationData = reservationData;
        })
        .addCase(changeSelectedDate, (state, action: PayloadAction<any>)=>{
            const selectedDate = action.payload;
            state.selectedDate = selectedDate;
        })
        .addCase(changeSelectedTimeSheetId, (state, action: PayloadAction<any>)=>{
            const selectedTimeSheetId = action.payload;
            state.selectedTimeSheetId = selectedTimeSheetId;
        })
    }
)
export default QueuingSystemSlice