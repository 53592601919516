import { createSlice } from '@reduxjs/toolkit';


const initialState: boolean = false;

const sideBarSlice = createSlice({
    name: 'stack_navigation',
    initialState,
    reducers: {
        openSideBar() {
            return true;
        },
        closeSideBar() {
            return false;
        },
        toggleOpenSideBar(state) {
            return !state
        }
    }
})

export const { openSideBar, closeSideBar, toggleOpenSideBar } = sideBarSlice.actions;
export default sideBarSlice.reducer;