import React from "react";
import { AiOutlinePhone, AiOutlineQuestionCircle } from 'react-icons/ai'
import { FaChalkboardTeacher, FaHandsHelping } from 'react-icons/fa'
import { HiOutlineBookOpen, HiOutlineChatAlt2, HiOutlineHome, HiOutlineLocationMarker, HiOutlineMail, HiOutlineNewspaper, HiOutlinePhotograph } from "react-icons/hi";
import { BiStore } from "react-icons/bi";
import { IoScale } from "react-icons/io5";
import { MdLiveTv, MdMiscellaneousServices, MdSportsHandball } from "react-icons/md";
import { GiHealthNormal, GiTrophyCup } from "react-icons/gi";
import { RiLineChartLine } from "react-icons/ri";
import { TiInfoLarge } from "react-icons/ti";
import routes from "utility/routes";
import { baseGroupOrContent } from "./baseGroupOrContent";
import { contestTypes } from ".";
import { surveysGroups } from "./surveysGroups";


export class MainMenuItem {
    constructor(
        public title: string,
        public icon: React.ComponentType,
        public color: string,
        public link: string
    ){}
}

const mainMenu: MainMenuItem[] = [
    new MainMenuItem('صفحه نخست', HiOutlineHome, '#00bfa5', '/'),
    new MainMenuItem('مددکاری', FaHandsHelping, '#00bcd4', '/1'),
    new MainMenuItem('آسان خرید', BiStore, '#ef6c00', 'https://asankharid.tejaratbank.ir'),
    new MainMenuItem('اخبار', HiOutlineNewspaper, '#dd2c00', routes.news.returnURL({ baseId: baseGroupOrContent.akhbar })),
    new MainMenuItem('انتشارات بانک', HiOutlineBookOpen, '#689f38', routes.publishers.returnURL()),
    new MainMenuItem('مراکز رفاهی ورزشی', MdSportsHandball, '#673ab7', routes.bases.returnURL({ parentId: '14' })),
    new MainMenuItem('سامانه بهداشت و درمان', GiHealthNormal, '#8e24aa', 'https://darman.tejaratbank.ir/'),
    new MainMenuItem('سامانه آموزش', FaChalkboardTeacher, '#26a69a', 'https://www.eltejaratbank.com/'),
    new MainMenuItem('خدمات و محصولات بانک', MdMiscellaneousServices, '#ef6c00', routes.bases.returnURL({ parentId: baseGroupOrContent.moarefyKhadamat })),
    new MainMenuItem('محاسبه BMI', IoScale, '#ff4081', routes.bmiCalculator.returnURL()),
    new MainMenuItem('محاسبه BMR', RiLineChartLine, '#4527a0', routes.bmrCalculator.returnURL()),
    new MainMenuItem('پیشنهادات', HiOutlineMail, '#afb42b', routes.suggestions.returnURL()),
    new MainMenuItem('نظرسنجی', HiOutlineChatAlt2, '#ff5722', routes.surveys.returnURL({ groupId: surveysGroups.syrvey })),
    new MainMenuItem('مسابقه', GiTrophyCup, '#01579b', routes.surveys.returnURL({ groupId: surveysGroups.contest })),
    new MainMenuItem('ارسال عکس', HiOutlinePhotograph, '#fb8c00', routes.photoContest.returnURL({ type: contestTypes.current })),
    new MainMenuItem('پخش زنده', MdLiveTv, '#689f38', routes.newsDetails.returnURL({ newsId: '2561', title: 'پخش زنده' })),
    new MainMenuItem('راهنمای تصویری', AiOutlineQuestionCircle,'#e45826', routes.pageContent.returnURL({ pageId: '144', title: 'راهنما' })),
    new MainMenuItem('تماس با ما', AiOutlinePhone, '#d32f2f', routes.pageContent.returnURL({ pageId: '142', title: 'تماس با ما' })),
    new MainMenuItem('درباره ما', TiInfoLarge, '#039be5', routes.pageContent.returnURL({ pageId: '141', title: 'درباره ما' })),
]

export default mainMenu;
