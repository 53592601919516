import { Avatar, Collapse, Container, Grid, IconButton, InputAdornment, InputBase, isWidthUp, makeStyles, Typography, withWidth, WithWidthProps } from '@material-ui/core'
import React from 'react'
import { HiMenu } from 'react-icons/hi';
import { ReactComponent as LogoText } from 'assets/images/logo_text.svg'
import { useAppDispatch, useAppSelector } from 'store';
import { openSideBar } from 'features/sidebar/sideBarSlice';
import { Link } from 'react-router-dom';
import routes from 'utility/routes';
import clsx from 'clsx';
import history from 'lib/router/history';
import LocationManager from 'utility/constants/LocationManager';
import { IoChevronBack, IoChevronUp, IoSearch, IoSearchOutline } from 'react-icons/io5';

const useStyles = makeStyles( theme=> ({
    container: {
        top: 0,
        left: '50%',
        transition: 'max-width 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '& header': {
            minHeight: 60,
            transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: theme.palette.background.paper,
            fill: theme.palette.text.primary,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[1],
            borderRadius: theme.shape.borderRadius*2,
            padding: theme.spacing(3,1),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(5,4),
            }
        },
        '&.fixed': {
            maxWidth:'100%',
            padding: 0,
            position: 'fixed',
            zIndex: theme.zIndex.appBar,
            transform: 'translateX(-50%)',
            '& + main': {
                paddingTop: theme.spacing(10),
                [theme.breakpoints.up('md')]: {
                    paddingTop: theme.spacing(14),
                }
            },
            '& header': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                fill: '#fff',
                borderRadius: 0,
                padding: theme.spacing(1.5,1),
                [theme.breakpoints.up('sm')]: {
                    padding: theme.spacing(1.75,1.5),
                }
            }
        },
        '& .MuiButtonBase-root': {
            [theme.breakpoints.down('sm')]: {
                padding: 8
            }
        },
    },
    toolbar: {
        height: '100%',  
    },
    appTitle: {
        padding: theme.spacing(2),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        minWidth: 0,
    },
    avatar: {
        width: 30,
        height: 30,
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.secondary.light,
        [theme.breakpoints.up('md')]: {
            width: 40,
            height: 40,
        }
    },
    title: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '50%',
        '& svg': {
            fill: 'inherit',
            height: 'auto',
            verticalAlign: 'middle',
            width: 110,
            [theme.breakpoints.up('sm')]: {
                width: 150,
            }
        }
    },
    backHandler: {
        transition: 'width,height,padding 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:not(.show)': {
            width: 0,
            height: 0,
            padding: '0 !important',
        }
    },
    searchBarContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginLeft: theme.spacing(3.5),
        marginRight: theme.spacing(3.5),
        backgroundColor: theme.palette.text.hint,
        borderBottomLeftRadius: theme.shape.borderRadius*2,
        borderBottomRightRadius: theme.shape.borderRadius*2,
        [theme.breakpoints.up('md')]: {
            borderBottomLeftRadius: theme.shape.borderRadius*2.5,
            borderBottomRightRadius: theme.shape.borderRadius*2.5,
            marginLeft: theme.spacing(5),
            marginRight: theme.spacing(5),
        }
    },
    searchInput: {
        backgroundColor: '#fff',
        padding: theme.spacing(0.5,1.5,0.5,0),
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1,2,1,0),
        }
    }
}));

const Header: React.FC<WithWidthProps> = ({ width }) => {
    const containerRef = React.useRef<HTMLDivElement>(null),
    [openSearchBar, setOpenSearchBar] = React.useState(false),
    [searchTitle, setSearchTitle] = React.useState(''),
    searchEl = React.useRef<HTMLInputElement>(null),
    dispatch = useAppDispatch(),
    classes = useStyles(),
    { navigationState, appTitle, userInfo } = useAppSelector( state=> ({
        navigationState: state.navigationState,
        userInfo: state.user.userInfo,
        appTitle: state.navigationState.length > 1 && state.navigationState.slice(-1)[0].title
    }))

    const scrollHandler = React.useCallback(
        function() {
            const isFixed = containerRef.current!.classList.contains('fixed');
            const padding = isWidthUp('md', width!) ? 32 : 16;

            if(isFixed && containerRef.current!.offsetTop+padding > window.scrollY) {
                containerRef.current!.classList.remove('fixed')
            } 
            else if( containerRef.current!.offsetTop < window.scrollY ) {
                if(document.body.clientHeight > window.innerHeight+100) {
                    containerRef.current!.classList.add('fixed')
                }
            }
        }, [width] 
    );

    const handleBack = React.useCallback(
        function() {
            if(navigationState.length > 1) {
                // history.replace(
                //     navigationState.slice(-2)[0]?.url
                // )
                history.goBack()
            }
        }, [navigationState]
    )

    React.useEffect( function() {
        
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [scrollHandler]);

    function handleOpenMenu() {
        dispatch( openSideBar() )
    }

    const toggleSearchBar = React.useCallback(()=> {
        setOpenSearchBar(lastVal => !lastVal);
    }, []);

    function submitByEnter(e: any) {
        if(e.which===13){
            e.preventDefault();
            if(searchTitle.length){
                searchHandler();
            }
        }
    }

    const searchHandler = React.useCallback(()=> {
        if(! searchTitle.length) {
            searchEl.current?.focus();
            return;
        }
        setSearchTitle('');
        setOpenSearchBar(false);
        history.push(routes.news.returnURL({ baseId: '0', title: searchTitle }));
    }, [searchTitle]);

    return (
        <Container fixed className={clsx(classes.container, 'hideOnPrint')} ref={containerRef}>
            <header>
                <Grid container justifyContent='space-between' alignItems='center' className={classes.toolbar}>
                    <Grid item>
                        <Grid container alignItems='center'>
                            <IconButton color='inherit' onClick={handleOpenMenu}>
                                <HiMenu />
                            </IconButton>
                            <IconButton color='inherit' onClick={toggleSearchBar}>
                                {
                                    openSearchBar
                                    ? <IoChevronUp />
                                    : <IoSearch />
                                }
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div className={classes.title}>
                        {
                            appTitle
                            ? <Typography variant='h6' className={classes.appTitle}>{appTitle}</Typography>
                            : (
                                <Link to={routes.home.returnURL()}>
                                    <LogoText />
                                </Link>
                            )
                        }
                    </div>
                    <Grid item>
                        <Grid container alignItems='center'>
                            <Link to={routes.profile.returnURL()}>
                                <Avatar className={classes.avatar} src={userInfo?.PhotoName && LocationManager.userImage(userInfo.PhotoName)} />
                            </Link>
                            <IconButton 
                                onClick={handleBack} 
                                color='inherit' 
                                className={clsx(classes.backHandler, navigationState.length > 1 && 'show')}
                            >
                                <IoChevronBack />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </header>
            {
                <Collapse in={openSearchBar} onEntered={()=> searchEl.current?.focus()}>
                    <div className={classes.searchBarContainer}>
                        <Grid container justifyContent='center'>
                            <Grid item xs={10} sm={8} md={6}>
                                <InputBase
                                    inputRef={searchEl}
                                    fullWidth
                                    onKeyDown={submitByEnter}
                                    value={searchTitle}
                                    onChange={(e)=> setSearchTitle(e.target.value)}
                                    className={classes.searchInput}
                                    placeholder="عنوان جستجو را وارد کنید..."
                                    endAdornment={( 
                                        <InputAdornment position="end"> 
                                            <IconButton onClick={searchHandler}>
                                                {
                                                    searchTitle?.length
                                                    ? <IoSearch />
                                                    : <IoSearchOutline />
                                                }
                                            </IconButton> 
                                        </InputAdornment>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Collapse>
            }
            <div></div>
        </Container>
    )
}

export default withWidth({ noSSR: true })(Header)
