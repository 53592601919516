import { createAsyncThunk, createReducer, PayloadAction } from '@reduxjs/toolkit';
import SOAP_request from 'lib/http-request/SOAP-request';

export interface IPageContentInfo {
    PageContentId: string;
    PageBaseId: string;
    Paragraph: string;
    ImageName: string;
    OrderNo: string;
    IsActive: string;
    Version: string;
    CreatedDate: string;
    CreatedBy: string;
    UpdatedDate: string;
    UpdatedBy: string;
}

interface IPageContentPayload {
    PageId: string
    PageContent: IPageContentInfo[]
}

export const fetchPageContent = createAsyncThunk(
    'pageContent/getPageData', 
    async (PageId: string) =>  {
        const { data: { VPageContent: PageContent } } = await SOAP_request({
            soapAction: 'GetPageData',
            method: 'POST',
            data: { 
                LanguageId: 11,
                PageId
            }
        });

        return { PageId, PageContent };
    }
);

const initialState: { [pageId: string ]: IPageContentInfo[] } = {};

const pagesContentSlice = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase( fetchPageContent.fulfilled, ( state, action: PayloadAction<IPageContentPayload> )=> {
                state[action.payload.PageId] = action.payload.PageContent
            })
    }
)

export default pagesContentSlice;