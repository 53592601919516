import React from 'react'
import { alpha, Avatar, Button, Drawer, Grid, List, makeStyles, Typography } from '@material-ui/core';
import { closeSideBar } from 'features/sidebar/sideBarSlice';
import { useAppDispatch, useAppSelector } from 'store';
import mainMenu from 'utility/constants/mainMenu';
import SideBarMenuItem from './SideBarMenuItem';
import { Link } from 'react-router-dom';
import routes from 'utility/routes';
import LocationManager from 'utility/constants/LocationManager';
import { HiOutlineLogout } from 'react-icons/hi';
import { openDialog } from 'features/dialog/dialogSlice';
import { logout } from 'features/user/userSlice';

const useStyles = makeStyles( theme=> ({
    root: {
        width: 290,
        padding: theme.spacing(0,1.5),
        [theme.breakpoints.up('sm')]: {
            width: 320,
            padding: theme.spacing(0,2.5),
        },
        [theme.breakpoints.up('md')]: {
            width: 330,
        },
        [theme.breakpoints.up('lg')]: {
            width: 350,
        },
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        overFlowX: 'hidden'
    },
    profile: {
        padding: theme.spacing(11,2,8),
    },
    avatar: {
        width: 50,
        height: 50,
        [theme.breakpoints.up('sm')]: {
            width: 65,
            height: 65,
        },
        backgroundColor: theme.palette.text.secondary,
        boxShadow: `-8px 8px 24px 0px ${alpha(theme.palette.text.secondary, 0.15)}`,
    },
    btn: {
        backgroundColor: alpha(theme.palette.text.primary, 0.125),
        '&:hover': {
            backgroundColor: alpha(theme.palette.text.primary, 0.2),
        }
    }
}));

const SideBarDrawer = () => {

    const classes = useStyles(),
    dispatch = useAppDispatch(),
    userInfo = useAppSelector( state=> state.user.userInfo ),
    open = useAppSelector( state=> state.sideBarOpen ),
    handleClose = React.useCallback( ()=> dispatch( closeSideBar() ), [dispatch] ),
    logoutHandler = React.useCallback(
        function() {
            dispatch( openDialog({
                title: 'خروج',
                body: 'آیا میخواهید از حساب کاربری خود خارج شوید؟',
                onOk: function() {
                  dispatch(logout())
                  handleClose()
                },
                okText: 'بله',
                cancelText: 'خیر'
            }))
        }, [dispatch, handleClose]
    );

    return (
        <Drawer
            open={open}
            onClose={handleClose}
        >
            <div className={classes.root}>
                <Grid container direction='column'>
                    <Grid container direction='column' item className={classes.profile} spacing={3}>
                        <Grid item container alignItems='center' spacing={2} wrap='nowrap' onClick={handleClose} component={Link} to={routes.profile.returnURL()}>
                            <Grid item>
                                <Avatar className={classes.avatar} src={userInfo?.PhotoName && LocationManager.userImage(userInfo.PhotoName)} />
                            </Grid>
                            <Grid item>
                                <Typography color='textPrimary' variant='h6'>{`${userInfo?.FirstName} ${userInfo?.LastName}`}</Typography>  
                                <Typography variant='subtitle2' color='textSecondary'>کدکاربری: {userInfo?.UserCode}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item dir='ltr'>
                            <Button
                                variant='contained' 
                                fullWidth 
                                color='inherit' 
                                size='small' 
                                startIcon={<HiOutlineLogout />} 
                                onClick={logoutHandler}
                                classes={{
                                    contained: classes.btn
                                }}
                            >
                                خروج از حساب کاربری
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <List disablePadding>
                            { mainMenu.map( menuItem=> <SideBarMenuItem key={menuItem.link} mainMenuItem={menuItem} /> ) }
                        </List>
                    </Grid>
                </Grid>
            </div>
        </Drawer >
    )
}

export default SideBarDrawer;
