import { IoIosMan, IoIosWoman } from "react-icons/io";

// Gender Types
export enum genders {
    MALE = 31,
    FEMALE = 32
}

export const genderTypes = {
    MALE: {
        typeId: genders.MALE,
        displayName: 'آقا',
        icon: IoIosMan, 
        color: '#8093f1'
    },
    FEMALE: {
        typeId: genders.FEMALE,
        displayName: 'خانم',
        icon: IoIosWoman, 
        color: '#f06c9b'
    }
}

// news score 
export enum newsScore {
    like = 1,
    dislike = -1
}

// contest types
export enum contestTypes {
    expired = 'expired',
    current = 'current'
}