import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { CancelToken } from "axios";
import REST_APICall from "lib/http-request/REST-request";

export interface IHealthCenterDetails {
    id: string,
    name: string,
    code: string,
    phone: string,
    dist: string|null,
    provinceId: string,
    provinceName: string,
    provinceCode: string,
    cityId: string,
    cityName: string,
    cityCode: string,
    typeId: string,
    typeName: string,
    typeCode: string,
    specialtyName: string,
    specialtyId: string,
    specialtyCode: string,
    address: string,
    lat: string,
    lon: string,
    totalRows: number,
    scores: {
        voters: number,
        scores: number
    },
}

export interface IFilter {
    id: string,
    name: string,
    code: string,
}
export interface IHealthCentersSliceState {
    centers: IHealthCenterDetails[]|null,
    currentPage: number,
    fillters: {
        Provinces: IFilter[],
        Specialties: IFilter[],
        CenterTypes: IFilter[],
        ProvinceCities: IFilter[]
    }
}

export const cleanHealthCenters = createAction('healthCenters/cleanHealthCenters');
export interface IFetchHealthCentersFilters {
    lat?: string|number,
    lon?: string|number,
    name?: string,
    code?: string,
    phone?: string,
    fax?: string,
    address?: string,
    city?: string,
    province?: string,
    type?: string,
    specialty?: string,
    page?: number,
    count?: string,
    order?:string,
    cancelToken?: CancelToken
}

export const fetchHealthCenters = createAsyncThunk(
    'healthCenters/fetchHealthCenters',
    function( filters: IFetchHealthCentersFilters, ThunkAPI) {
        navigator.geolocation.getCurrentPosition(
            ({ coords: { latitude, longitude } })=> {
                ThunkAPI.dispatch(filterHealthCenters({
                    ...filters,
                    lat: latitude,
                    lon: longitude,
                }))
            },
            ()=> {
                ThunkAPI.dispatch(filterHealthCenters(filters));
            }
        );
    }
)

export const filterHealthCenters = createAsyncThunk(
    'healthCenters/filterHealthCenters',
    async ({
        lat = "0",
        lon = "0",
        name = "",
        code = "",
        phone = "",
        fax = "",
        address = "",
        city = "0",
        province = "0",
        type = "0",
        specialty = "0",
        page = 0,
        count = "10",
        order ="0",
        cancelToken
    }: IFetchHealthCentersFilters)=> {

        const { data: { result: centers } } = await REST_APICall({
            domain: 'amaken',
            url: '/Centers/FilterCenters',
            method: 'POST',
            data: {lat,lon,name,code,phone,fax,address,city,province,type,specialty,page,count,order},
            cancelToken
        });

        return { centers, currentPage: page };
    }
)

export const fetchFiltersOptions = createAsyncThunk(
    'healthCenters/fetchFiltersOptions',
    async function(provinceId: string | undefined, ThunkAPI) {

        ThunkAPI.dispatch(cleanCyties());

        const { data: { result: Provinces } } = await REST_APICall({
            domain: 'amaken',
            url: '/Province/GetProvince',
            method: 'GET',
        })

        const { data: { result: Specialties } } = await REST_APICall({
            domain: 'amaken',
            url: '/Specialty/GetSpecialty',
            method: 'GET',
        })

        const { data: { result: CenterTypes } } = await REST_APICall({
            domain: 'amaken',
            url: '/CenterTypes/GetCenterTypes',
            method: 'GET',
        })

        let ProvinceCities = [];

        if(provinceId) {
            const { data: { result } } = await REST_APICall({
                domain: 'amaken',
                url: '/City/GetProvinceCities',
                method: 'GET',
                params: { id: provinceId }
            });

            ProvinceCities = result;
        };

        return { Provinces, Specialties, CenterTypes, ProvinceCities };
    }
)

const cleanCyties = createAction('healthCenters/cleanCyties');
export const fetchProvinceCities = createAsyncThunk(
    'healthCenters/fetchProvinceCities',
    async function(provinceId: string, ThunkAPI) {
        ThunkAPI.dispatch(cleanCyties());
        const { data: { result: ProvinceCities } } = await REST_APICall({
            domain: 'amaken',
            url: '/City/GetProvinceCities',
            method: 'GET',
            params: { id: provinceId }
        });

        return ProvinceCities;
    }
)

const initialState: IHealthCentersSliceState = {
    centers: null,
    currentPage: 0,
    fillters: {
        Provinces: [],
        Specialties: [],
        CenterTypes: [],
        ProvinceCities: []
    }
}

const healthCentersSlice = createReducer<IHealthCentersSliceState>(
    initialState,
    (builder) => {
        builder
            .addCase( filterHealthCenters.fulfilled, ( state, action: PayloadAction<any> )=> {
                const { currentPage, centers } = action.payload;
                state.centers = state.centers 
                    ? state.centers.concat(centers) 
                    : centers
                state.currentPage = currentPage;
            })
            .addCase( cleanHealthCenters, ( state )=> {
                state.centers = null;
            })
            .addCase( fetchFiltersOptions.fulfilled, ( state, action: PayloadAction<any> )=> {
                state.fillters = action.payload;
            })
            .addCase( cleanCyties, ( state )=> {
                state.fillters.ProvinceCities = [];
            })
            .addCase( fetchProvinceCities.fulfilled, ( state, action: PayloadAction<any> )=> {
                state.fillters.ProvinceCities = action.payload;
            })
    }
);

export default healthCentersSlice;