import { Grid } from '@material-ui/core'
import RenderTextField from 'components/form-fields/RenderTextField'
import Btn from 'components/styled-components/Btn'
import Text from 'components/styled-components/Text'
import { putZeroBeforeNumber } from 'lib'
import React from 'react'
import { Field, reduxForm, FormErrors, InjectedFormProps } from 'redux-form'
import { useAppDispatch } from 'store'
import resetPasswordPhases from 'utility/constants/resetPasswordPhases';
import SOAP_request from 'lib/http-request/SOAP-request';
import { showAlert } from 'features/alert/alertSlice';
import history from 'lib/router/history'

interface IResetPasswordFormOwnProps {
    changePhase: Function,
    sendCodeAgain: Function,
    phoneNumber: string
}

export interface IResetPasswordFormValues {
    ActivationCode: string,
    NewPassword: string,
    ConfirmNewPassword: string
}

function isEnd(time: string) {
    return time === '00:00'
}

const ResetPasswordForm: React.FC<IResetPasswordFormOwnProps
&InjectedFormProps<IResetPasswordFormValues, 
IResetPasswordFormOwnProps>
> = ({ handleSubmit, sendCodeAgain, changePhase, phoneNumber, submitting })=> {

    const [timeOfUsingCode, setTimeOfUsingCode] = React.useState('01:00'),
    [resetCodeLoading, setResetCodeLoading] = React.useState(false),
    dispatch = useAppDispatch();

    function handleChangeNumber() {
        changePhase(resetPasswordPhases.ENTER_PHONE_NUMBER)
    }

    React.useEffect( function() {
        const intervalId = setInterval( function() {
            if ( isEnd(timeOfUsingCode) ) return;
            let [m, s]: any = timeOfUsingCode.split(':');
            s = +s - 1;
            if(s === -1) {
                s = 59;
                m = +m - 1;
            }
            setTimeOfUsingCode(`${putZeroBeforeNumber(m)}:${putZeroBeforeNumber(s)}`)
        }, 1000 );
        return ()=> clearInterval(intervalId)
    }, [timeOfUsingCode])

    const getActivationCodeAgain = React.useCallback( async function() {
        setResetCodeLoading(true)
        Promise.resolve(sendCodeAgain(phoneNumber))
        .then( function() {
            setTimeOfUsingCode('01:00')
        })
        .finally( function() {
            setResetCodeLoading(false)
        })
        
    }, [sendCodeAgain,phoneNumber])


    const submitActivation = React.useCallback( 
        async function( { ActivationCode, NewPassword }: IResetPasswordFormValues ) {  
            try {
                await SOAP_request({
                    method: 'POST',
                    soapAction: 'ResetPasswordUsingPhoneNumber',
                    data: {
                        MobileNo: phoneNumber,
                        confirmCode: ActivationCode, 
                        NewPassword
                    },
                });

                dispatch(
                    showAlert({
                        message: 'گذرواژه با موفقیت تغییر یافت',
                        severity: 'success'
                    })
                );
                
                history.replace('/login');

            } catch {
                dispatch(
                    showAlert({
                        message: 'اطلاعات وارد شده صحیح نیست',
                        severity: 'error'
                    })
                )
            }
        }, [phoneNumber, dispatch]
    );

    return (
        <Grid 
        container 
        spacing={4} 
        component='form' 
        autoComplete="off" 
        onSubmit={handleSubmit(submitActivation)} 
        noValidate>
            <input type='hidden' autoComplete='off' />
            <Grid item container spacing={2}>
                <Grid item container>
                    <Grid item xs={12}>
                        <Text variant='body2'>{`کد احراز هویت به شماره ${phoneNumber} ارسال شد`}</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Btn color='primary' size='small' onClick={handleChangeNumber}>ویرایش شماره</Btn>
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={12} >
                        <Field 
                            type="number"
                            dir='ltr'
                            fullWidth
                            autoFocus
                            variant='outlined'
                            label='کد احراز حویت'
                            name="ActivationCode" 
                            placeholder='----'
                            inputProps={{
                                className: 'space-between-letters text-center'
                            }}
                            maxLenght={4}
                            component={RenderTextField}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Field 
                            type="password"
                            dir='ltr'
                            label='گذرواژه جدید'
                            variant='outlined'
                            fullWidth
                            autocomplete="new-password" 
                            name="NewPassword" 
                            component={RenderTextField}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Field 
                            type="password"
                            dir='ltr'
                            label='تکرار گذرواژه جدید'
                            variant='outlined'
                            fullWidth
                            autocomplete="new-password" 
                            name="ConfirmNewPassword" 
                            component={RenderTextField}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container justifyContent='center' spacing={2}>
                <Grid item xs={12}>
                    <Btn color='primary' loading={submitting} fullWidth type="submit" variant='contained'>تایید و ورود</Btn>
                </Grid>
                <Grid item>
                    <Btn 
                    disabled={ ! isEnd(timeOfUsingCode) } 
                    loading={resetCodeLoading} 
                    color='default' 
                    variant='outlined'
                    onClick={getActivationCodeAgain}
                    >
                        {
                            ! isEnd(timeOfUsingCode) && (
                                <div style={{ width: 45 }}>{timeOfUsingCode}</div>
                            )
                        }
                        ارسال مجدد کد
                    </Btn>
                </Grid>
            </Grid>
        </Grid>
    )
}

const validate = ( values: IResetPasswordFormValues ) => {
    const errors: FormErrors<IResetPasswordFormValues> = {};
    if(! values.ActivationCode || !(/^[0-9]{4}$/).test(values.ActivationCode) ) {
        errors.ActivationCode = 'لطفا کد تایید را به دقت وارد کنید'
    }
    if(! values.NewPassword){
        errors.NewPassword = 'گذرواژه جدید خود را وارد کنید'
    }
    if(values.NewPassword && values.NewPassword.length < 6){
        errors.NewPassword = 'حداقل تعداد کاراکتر مورد قبول 6 کاراکتر میباشد'
    }
    if(values.NewPassword && values.NewPassword.includes(' ')){
        errors.NewPassword = 'استفاده از فاصله در گذرواژه مجاز نیست'
    }
    if(!values.ConfirmNewPassword){
        errors.ConfirmNewPassword = 'تکرار گذرواژه جدید خود را وارد کنید'
    }
    if(values.ConfirmNewPassword !== values.NewPassword){
        errors.ConfirmNewPassword = 'تکرار گذرواژه با گذرواژه برابر نیست'
    }
    return errors;
}

export default reduxForm<IResetPasswordFormValues, IResetPasswordFormOwnProps>({
    form : 'resetPasswordForm',
    validate
})(ResetPasswordForm);
