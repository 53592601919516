import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import appTheme from 'theme/appTheme';
import App from './App';
import jMoment from "moment-jalaali";
import 'swiper/swiper-bundle.min.css'; // core Swiper
// import 'swiper/modules/navigation/navigation.min.css'; // Navigation Swiper module
// import 'swiper/modules/effect-fade/effect-fade.min.css'; // FadeEffetct Swiper module
// import 'swiper/modules/pagination/pagination.min.css'; // Pagination Swiper module
import "cropperjs/dist/cropper.css"; // css of cropper js
import 'react-image-lightbox/style.css' // css of react light box
import 'react-h5-audio-player/lib/styles.css'; // audio player
// import reportWebVitals from './reportWebVitals';

// config persian lang for pickers
jMoment.loadPersian({ dialect: "persian", usePersianDigits: false });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={appTheme}>
        <App />
        <CssBaseline />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js');
}