import { alpha, Avatar, ListItem, ListItemAvatar, ListItemText, makeStyles } from "@material-ui/core";
import { closeSideBar } from "features/sidebar/sideBarSlice";
import { getLinkProps } from "lib";
import React from "react";
import { useLocation } from "react-router";
import { useAppDispatch } from "store";
import { MainMenuItem } from "utility/constants/mainMenu";

const useStyles = makeStyles( theme=> ({
    listIconRoot: {
        minWidth: 60,
        [theme.breakpoints.down('sm')]: {
            minWidth: 54
        }
    },
    listIconAvatar: (menuItem: MainMenuItem)=> ({
        backgroundColor:  alpha(menuItem.color, 0.9),
        boxShadow: `-8px 8px 24px 0px ${alpha(menuItem.color, 0.2)}`,
        width: 42,
        height: 42,
        [theme.breakpoints.down('sm')]: {
            width: 40,
            height: 40
        }
    }),
    itemButton: {
        textAlign: 'right',
        marginBottom: theme.spacing(1.5),
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
            backgroundColor: (menuItem: MainMenuItem)=> alpha(menuItem.color, 0.05)
        },
        '& .MuiTypography-root': {
            ...theme.typography.subtitle2,
            [theme.breakpoints.up('sm')]: {            
                fontSize: 15,
            }

        },
        [theme.breakpoints.up('sm')]: {            
            marginBottom: theme.spacing(2),
        }
    },
    selectedItem: (menuItem: MainMenuItem)=> ({
        color: menuItem.color,
        backgroundColor: alpha(menuItem.color, 0.08) + '!important',
    })
}));

const SideBarMenuItem: React.FC<{ mainMenuItem: MainMenuItem }> = ({ mainMenuItem })=> {
    const classes= useStyles(mainMenuItem),
    pathName = useLocation()['pathname'],
    dispatch = useAppDispatch();

    const closeMainMenu = React.useCallback(function() {
        dispatch( closeSideBar() );
    }, [dispatch]);

    const linkProps = getLinkProps(mainMenuItem.link);

    return (
        <ListItem 
            {...linkProps}
            button
            disableRipple
            onClick={closeMainMenu}
            selected={pathName===mainMenuItem.link}
            classes={{
                selected: classes.selectedItem,
                button: classes.itemButton,
            }}
        >
            <ListItemAvatar classes={{ root: classes.listIconRoot }}>
                <Avatar className={classes.listIconAvatar} variant="rounded">
                    <mainMenuItem.icon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={mainMenuItem.title} />
        </ListItem>
    )
}

export default SideBarMenuItem;