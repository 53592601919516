import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as PageNotFoundSVG } from 'assets/images/pageNotPage.svg'
import React from 'react'

const useStyles = makeStyles(theme=> ({
    container: {
        padding: theme.spacing(4),
        margin: 0,
        width: '100%'
    }
}))

function PageNotFound() {

    const classes = useStyles()

    return (
        <Grid container className={classes.container} spacing={4} justifyContent='center'>
            <Grid item xs={12}>
                <Typography align='center' color='textSecondary' variant='h5'>
                    404 | صفحه مورد نظر یافت نشد!
                </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
                <PageNotFoundSVG />
            </Grid>
        </Grid>
    )
}

export default PageNotFound
