import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type INavigaionState = {
    url: string,
    title: string
}[]

const initialState: INavigaionState = [];

const navigationStateSlice = createSlice({
    name: 'stack_navigation',
    initialState,
    reducers: {
        setNavigationState(state, action: PayloadAction<INavigaionState>) {
            return action.payload;
        },
        resetNavigationState(state) {
            state = [];
        },
        updateAppTitle(state, action: PayloadAction<string>) {
            state[state.length-1].title = action.payload;
        }
    }
})

export const { resetNavigationState, setNavigationState, updateAppTitle } = navigationStateSlice.actions;
export default navigationStateSlice.reducer;