import { Container, makeStyles } from '@material-ui/core'
import { getUserProfile } from 'features/user/userSlice'
import { getNotificationToken } from 'lib/firebase'
import globalStorage from 'lib/globalStorage'
import SOAP_request from 'lib/http-request/SOAP-request'
import AppRoutes from 'lib/router/AppRoutes'
import history from 'lib/router/history'
import platform from 'platform'
import APP_INFO from 'app.json'
import React from 'react'
import { useAppDispatch } from 'store'
import globalStorageItems from 'utility/constants/globalStorageItems'
import routes from 'utility/routes'
import Header from './Header'
import SideBarDrawer from './SideBarDrawer'

const useStyles = makeStyles( theme=> ({
    appRoot: {
        minHeight: '100vh',
        width: '100%',
        overflowX: 'hidden',
        display: 'flex',
        flexFlow: 'column wrap',
        paddingTop: theme.spacing(2),
        '@media print': {
            paddingTop: 0
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(4),
        },
    },
    main: {
        flexGrow: 1,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
    }
}))

const Layout: React.FC = ()=> {

    const classes = useStyles(),
    dispatch = useAppDispatch();

    React.useEffect( ()=> {
        history.listen( ()=> window.scrollTo(0,0));
        // get user profile info
        dispatch(getUserProfile());
        // get notification key and send to server
        getNotificationToken()
        .then( async function( NotifKeyString ) {
            globalStorage.setItem(globalStorageItems.NOTIFICATION_TOKEN, NotifKeyString);
            await SOAP_request({
                soapAction: 'AddUserClient',
                method: 'POST',
                data: { 
                    ClientId: NotifKeyString, 
                    OSTypeBaseId: (
                        platform.os?.toString().toLowerCase().includes('android')
                        ? "31"
                        : platform.os?.toString().toLowerCase().includes('ios')
                        ? "30"
                        : platform.os?.toString().toLowerCase().includes('windows')
                        ? "32"
                        : platform.os?.toString().toLowerCase().includes('mac')
                        ? "33"
                        : "_"
                    ) 
                }
            });
            await SOAP_request({
                method: 'POST',
                soapAction: 'AddRequestInfo',
                data: {
                    clientId: NotifKeyString,
                    requestInfo: `-@|@PWA v${APP_INFO.version}@|@${platform.manufacturer||'-'}@|@${platform.name?.toString()}@|@${platform.version?.toString()}@|@${platform.os?.toString().toLowerCase().includes('android').toString()}@|@true@|@false@|@${platform.os?.toString().toLowerCase().includes('ios').toString()}@|@${platform.os?.toString().toLowerCase().includes('mac').toString()}@|@false@|@${platform.os?.toString().toLowerCase().includes('windows').toString()}@|@true`,
                }
            });
        });
      }, [dispatch]);

    return (
        <div className={classes.appRoot}>
            <Header />
            <Container fixed component='main' className={classes.main}>
                <AppRoutes routes={Object.values(routes)} />
            </Container>
            <SideBarDrawer />
        </div>
    )
}

export default Layout
