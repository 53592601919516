import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { showAlert } from 'features/alert/alertSlice';
import { IUserToken, logout } from 'features/user/userSlice';
import globalStorage from 'lib/globalStorage';
import { globalDispatch } from 'store';
import LocationManager from 'utility/constants/LocationManager';
import globalStorageItems from 'utility/constants/globalStorageItems';


interface IRequestConfig<TBody> extends AxiosRequestConfig<TBody> {
    soapAction?: string
}

const axiosInstance = axios.create({
    headers: { 
        'Pragma': 'no-cache',
        'Accept': '*/*',
    },
    baseURL: LocationManager.SOAP_SERVICE,
});

/**
 * @example
 * 
 * import SOAP_request from 'lib/apiCall';
 * 
 * await SOAP_request({ method: 'get', url: 'requestURL', ...options })
 */
const SOAP_request = function<TData = any, TBody = any> ( { soapAction, ...config }: IRequestConfig<TBody>) {

    if(soapAction) {
        config = {
            ...config,
            headers: { 
                'Content-Type': 'text/xml; charset=utf-8',
                'SOAPAction': `http://tempuri.org/${soapAction}`,
                ...config.headers 
            },
            data: (
                `<?xml version="1.0" encoding="utf-8"?>
                <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                <soap:Body>
                    <${soapAction} xmlns="http://tempuri.org/">
                        ${
                            !(config.data && 'UserId' in config.data) 
                            ? `<UserId>${globalStorage.getItem<IUserToken>(globalStorageItems.USER_TOKEN)?.SESSION_ID}</UserId>`
                            : ''
                        }
                        ${ 
                            Object.entries(config.data || {})
                            .map( ([tag,value])=> `<${tag}>${String(value).trim()}</${tag}>` )
                            .join('\n') 
                        }
                    </${soapAction}>
                </soap:Body>
                </soap:Envelope>`
            ) as any
        }
    }
    
    return new Promise<AxiosResponse<TData, TBody>>((resolvation, rejection)=> {
        axiosInstance(config)
        .then( function( res ) {
            // handling SOAP Requests
            if ( typeof res.data === 'string') {
                let parser = new DOMParser();
                let resXML = parser.parseFromString(res.data, "text/xml");
                let response: string = resXML.querySelector(`${soapAction}Result`)!.innerHTML;
                let result;
                try { result = JSON.parse(response)} 
                catch { result = response }

                if (typeof result === 'number' && result < 0) {
                    if(result === -1) {
                        globalDispatch(logout());
                    } else {
                        globalDispatch(showAlert({
                            message: 'مشکل در ارتباط با سرور',
                            severity: 'error'
                        }));
                    }
                    rejection(result);
                } else {
                    resolvation({
                        ...res,
                        data: result
                    });
                }
            }
        })
        .catch( function(err: AxiosError) { 
            if(err.message !== 'APICALL_CANCELED') {
                rejection(err.response?.data);
                globalDispatch(showAlert({
                    message: 'مشکل در ارتباط با سرور',
                    severity: 'error'
                }));
            }
        })
    })
}

export default SOAP_request;