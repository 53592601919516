// base contents and superbase contents HARD CODE (News)
export enum baseGroupOrContent {
    manabeAzmoon = '1',
    moarefyKhadamat = '23',
    omurpersonel = '45',
    akhbar = '160',
    manzome = '54',
    galleryChandResane = '24',
    refahi_varzeshi = '14',
    amoozesh = '19',
    userNotification = '161',
    publishers = '221',
    specialNewsSlides = '0',
}

export const baseGroupOrContentDisplayName = {
    [baseGroupOrContent.manabeAzmoon]: 'منابع آزمون',
    [baseGroupOrContent.moarefyKhadamat]: 'معرفی خدمات و محصولات بانک',
    [baseGroupOrContent.omurpersonel]: 'امور پرسنلی',
    [baseGroupOrContent.akhbar]: 'اخبار',
    [baseGroupOrContent.manzome]: 'منظومه تجارت',
    [baseGroupOrContent.galleryChandResane]: 'گالری چندرسانه ای',
    [baseGroupOrContent.refahi_varzeshi]: 'مراکز رفاهی ورزشی',
    [baseGroupOrContent.amoozesh]: 'آموزش',
    [baseGroupOrContent.userNotification]: 'اعلانات',
    [baseGroupOrContent.publishers]: 'انتشارات بانک',
    [baseGroupOrContent.specialNewsSlides]: 'اخبار ویژه'
}
