import { Grid } from '@material-ui/core'
import RenderTextField from 'components/form-fields/RenderTextField'
import Btn from 'components/styled-components/Btn'
import React from 'react'
import { Field, reduxForm, FormErrors, InjectedFormProps } from 'redux-form'

interface IPhoneNubmerInputOwnProps {
    onPhoneSubmit: any
}

export interface IPhoneNubmerInputFormValues {
    phoneNumber: string
}

const PhoneNubmerInput: React.FC<IPhoneNubmerInputOwnProps
&InjectedFormProps<IPhoneNubmerInputFormValues, 
IPhoneNubmerInputOwnProps>
> = ({ onPhoneSubmit, handleSubmit, submitting })=> {
    
    async function submitHandler(formValue: IPhoneNubmerInputFormValues) {
        await onPhoneSubmit(formValue.phoneNumber)
    }

    return (
        <Grid 
            container 
            spacing={4} 
            component='form' 
            autoComplete='off' 
            onSubmit={handleSubmit(submitHandler)} 
            noValidate
        >
            <Grid item xs={12} >
                <Field 
                    type="number"
                    label="شماره تلفن همراه" 
                    variant='outlined'
                    dir='ltr'
                    autoFocus
                    fullWidth
                    name="phoneNumber" 
                    placeholder='09---------'
                    inputProps={{
                        className: 'space-between-letters'
                    }}
                    maxLenght={11}
                    component={RenderTextField} 
                />
            </Grid>
            <Grid item xs={12}>
                <Btn color='primary' loading={submitting} fullWidth type="submit" variant='contained'>دریافت کد احراز هویت</Btn>
            </Grid>
        </Grid>
    )
}

const validate = ( values: IPhoneNubmerInputFormValues ) => {
    const errors: FormErrors<IPhoneNubmerInputFormValues> = {};
    if(! values.phoneNumber) {
        errors.phoneNumber = 'لطفا شماره همراه خود را وارد کنید'
    }
    if( values.phoneNumber && !(/^09[0-9]{9}$/).test(values.phoneNumber) ) {
        errors.phoneNumber = 'شماره وارد شده معتبر نمیباشد'
    }
    return errors;
}

export default reduxForm<IPhoneNubmerInputFormValues, IPhoneNubmerInputOwnProps>({
    form : 'enterPhoneNubmerInputPhase',
    validate
})(PhoneNubmerInput);
