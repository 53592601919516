import { alpha, Grid, makeStyles } from "@material-ui/core";
import { ReactComponent as Logo } from 'assets/images/main_logo.svg';
import LoginForm, { LoginFormValues } from "components/LoginForm";
import { Redirect } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { Route, Switch } from 'react-router';
import ResetPassword from "components/login/ResetPassword";
import { login } from "features/user/userSlice";



const useStyle = makeStyles( theme=> ({
    root : {
        flexGrow : 1,
        backgroundColor : theme.palette.background.paper,
    },
    container: {
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(15)
        }
    },
    imageSection: {
        '& svg': {
            width: 350,
            height: 350
        },
        [theme.breakpoints.down('sm')]: {
            '& svg': {
                width: 150,
                height: 150
            }
        },
        [theme.breakpoints.up('md')]: {            
            height: '100vh',
            background: `linear-gradient(to right, transparent, ${alpha(theme.palette.info.dark, 0.3)} 70% )`,
        }
      },
}));

const Login: React.FC = () => {
    const classes = useStyle(),
    isUserLoggedIn = useAppSelector( state=> state.user.isLogin ),
    dispatch = useAppDispatch();

    async function formSubmitHandler({ username, password, rememberMe }: LoginFormValues) {
        await dispatch(login({ username, password, rememberMe }));
    }

    if(isUserLoggedIn) return <Redirect to='/'/>

    return ( 
        <Grid container alignContent="center" className={classes.root}>
            <Grid item xs={12} md={7}>
                <Grid 
                    container 
                    justifyContent="center" 
                    alignItems="center" 
                    className={classes.imageSection}
                >
                    <Logo />
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} className={classes.container}>
                <Switch>
                    <Route 
                        path='/login' 
                        exact 
                        render={ ()=> <LoginForm onFormSubmit={formSubmitHandler} /> }
                    />
                    <Route path='/login/reset-password' component={ResetPassword} />
                    <Route path='*' render={ ()=> <Redirect to='/login' /> } />
                </Switch>
            </Grid>
        </Grid>
    );
}


export default Login;