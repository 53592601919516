import PageNotFound from 'pages/PageNotFound';
import React, { memo, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { IRoute } from './Route';
import { Helmet } from "react-helmet";
import LoadingFallback from 'components/generic/LoadingFallback';
import { resetNavigationState, setNavigationState } from 'features/navigation/navigationStateSlice';

interface IAppRoutesProps {
    routes: Array<IRoute<any>>
}

const AppRoutes: React.FC<IAppRoutesProps> = ({ routes: routesArr })=> {

    const dispatch = useAppDispatch();

    return (
        <Switch>
            {
                routesArr.map( route=> (
                    <Route 
                        path={route.path} 
                        key={route.path}
                        exact 
                        render={ function(props) {
                            const navigationStack = routesArr
                            // Get all routes that contain the current one.
                            .filter(({ path }) => props.match.path.includes(path))
                            // Swap out any dynamic routes with their param values.
                            .map(({ path, title }) => ({
                                url: Object.keys(props.match.params).length
                                    ? Object.keys(props.match.params).reduce(
                                        (path, param) => path.replace(
                                        RegExp(`:${param}[?]?`), props.match.params[param] as string
                                        ).replace(/:(.)+?\?/g,''), path
                                    )
                                    : path,
                                title: typeof title === 'function' ? title(props) : title,
                            }));

                            dispatch(setNavigationState(navigationStack));

                            return (
                                <Suspense fallback={<LoadingFallback />}>
                                    <Helmet>
                                        {
                                            route.title
                                            ? <title>{typeof route.title === 'function' ? route.title(props) : route.title} | خانواده تجارت</title>
                                            : <title>خانواده تجارت</title>
                                        }
                                    </Helmet>
                                    <route.Component {...props} />
                                </Suspense>
                            )
                        } }
                    />
                ))
            }
            <Route 
                render={ function(props: any) {
                    dispatch(resetNavigationState());
                    return <PageNotFound {...props} />
                }} 
            />
        </Switch>
    );
}

export default memo(AppRoutes);