import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { showAlert } from 'features/alert/alertSlice';
import { globalDispatch } from 'store';
import LocationManager from 'utility/constants/LocationManager';

interface IResponse<TDataType = any> {
    code: number,
    status: boolean,
    title: string,
    message: string,
    result: TDataType
}

interface IRequestConfig<TBody> extends AxiosRequestConfig<TBody> {
    domain: keyof typeof LocationManager.REST_API
}

const axiosInstance = axios.create();

/**
 * @example
 * 
 * import REST_APICall from 'lib/apiCall';
 * 
 * await REST_APICall({ method: 'get', url: 'requestURL', ...options })
 */
const REST_APICall = function<TData = any, TBody = any> ( { domain, ...config }: IRequestConfig<TBody>) {

    config = {
        ...config,
        baseURL: LocationManager.REST_API[domain],
    }
        
    return new Promise<AxiosResponse<IResponse<TData>, TBody>>((resolvation, rejection)=> {
        axiosInstance.request<IResponse<TData>>(config)
        .then( function( res ) {
            if(res.data.code < 0) {
                globalDispatch(showAlert({
                    message: res.data.title,
                    severity: 'error'
                }));
                rejection(res.data);
            } else {
                resolvation(res);
            }
        })
        .catch( function(err: AxiosError<IResponse>) { 
            if(err.message !== 'APICALL_CANCELED') {
                rejection(err.response?.data);
                globalDispatch(showAlert({
                    message: 'مشکل در ارتباط با سرور',
                    severity: 'error'
                }));
            }
        })
    })
}


interface IResponseV2<TDataType = any> {
    code: number,
    status: boolean,
    title: string,
    message: string,
    Result: TDataType
}
export const REST_APICallV2 = function<TData = any, TBody = any> ( { domain, ...config }: IRequestConfig<TBody>) {

    config = {
        ...config,
        baseURL: LocationManager.REST_API[domain],
    }
        
    return new Promise<AxiosResponse<IResponseV2<TData>, TBody>>((resolvation, rejection)=> {
        axiosInstance.request<IResponseV2<TData>>(config)
        .then( function( res ) {
            if(res.data.code < 0) {
                globalDispatch(showAlert({
                    message: res.data.title,
                    severity: 'error'
                }));
                rejection(res.data);
            } else {
                resolvation(res);
            }
        })
        .catch( function(err: AxiosError<IResponse>) { 
            if(err.message !== 'APICALL_CANCELED') {
                rejection(err.response?.data);
                globalDispatch(showAlert({
                    message: 'مشکل در ارتباط با سرور',
                    severity: 'error'
                }));
            }
        })
    })
}

export default REST_APICall;