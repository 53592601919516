import { BtnColor } from 'components/styled-components/Btn';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PropTypes } from '@material-ui/core';

export interface IDialogPayload {
    title?: string
    titleAlign?: PropTypes.Alignment
    body?: string | React.ReactElement
    noAction?: boolean
    okText?: string
    cancelText?: string
    onOk?: Function
    onCancel?: Function
    okColor?: BtnColor
    cancelColor?: BtnColor
    fullWidth?: boolean
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
    scroll?: 'body' | 'paper',
    fullScreen?: boolean
};

export interface dialogState extends IDialogPayload {
    key: number
}

export type TDialogPack = Array<dialogState>

interface IDialogReducerState {
    dialogPack: TDialogPack,
    open: boolean
}

const initialState: IDialogReducerState = {
    dialogPack: [],
    open: false
}

const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        openDialog(state, action: PayloadAction<IDialogPayload>) {
            state.dialogPack.push({ ...action.payload, key: new Date().getTime() });
        },
        closeDialog(state) {
            state.open = false;
        },
        clearDialogPack(state) {
            return {
                open: true,
                dialogPack: state.dialogPack.slice(1)
            }
        }
    }
});

export const { clearDialogPack, closeDialog, openDialog } = dialogSlice.actions;
export default dialogSlice.reducer;