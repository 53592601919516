import firebase from 'firebase/app';
import "firebase/messaging";
import { firebaseConfig, vapidKey } from 'utility/constants/firebaseConfig';
import { globalDispatch } from "store";
import { openDialog } from 'features/dialog/dialogSlice';

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging.isSupported()
? firebase.messaging()
: null;

export const notificationStatus = async()=> {
    if( navigator.permissions ) {
        const permission = await navigator.permissions.query({ name: 'notifications' });
        return permission.state;
    }
}

export const getNotificationToken = async()=> {
    return new Promise<string>( async function(resolve, reject) {
        
        if( firebase.messaging.isSupported() ) {
            const currentNotificationStatus = await notificationStatus();
            
            const _getToken = () => {
                return messaging!.getToken({ vapidKey } )
            }
    
            switch(currentNotificationStatus) {

                case "prompt": 
                    setTimeout(function() {
                        globalDispatch(
                            openDialog({
                                title: "آیا مایل به فعال سازی اعلانات هستید؟",
                                body: 'برای فعال کردن نوتیفیکیشن، روی فعال سازی کلیک کنید و در مرحله بعد روی دکمه Allow کلیک کنید.',
                                onOk: async function() {
                                    const result = await Notification.requestPermission();
                                    if(result === "granted") resolve( _getToken() );
                                },
                                okText: "فعال سازی",
                                cancelText: "بعدا",
                            })
                        )
                    }, 1500)
                    break;
                
                case "granted": 
                    resolve( _getToken() );
                    break;
                
                default :
                    reject('Notification permission denied!');
                    break;

            }
        } else {
            reject('Notifications are not supported!');
        }
    })
};

// for foreground notifications
export const onMessageListener = ()=> (
    new Promise( (resolve)=> {
        if( firebase.messaging.isSupported() ) {
            messaging!.onMessage(( payload )=> {
                resolve(payload);
            });
        }
    })
)