import { createAsyncThunk, createReducer, PayloadAction } from '@reduxjs/toolkit';
import SOAP_request from 'lib/http-request/SOAP-request';

export interface IsponserDetails {
    ContestId: string
    ContestTitle: string
    Description: string
    ImageName: string
    Section: string
    SponsorId: string
    SubSection: string
    TBaseId: string
    TNewsId: string
    TSuperBaseId: string
    Title: string
    Url: string
}


interface IState {
    sponsers: Array<IsponserDetails>|null
}


const initialState: IState = {
    sponsers: null,
};

export const fetchHomeBnners = createAsyncThunk(
    'homePage/sponsers',
    async function() {
        const { data: { VSponsor: sponsers } } = await SOAP_request({
            soapAction: 'GetSponsors',
            method: 'POST',
            data: {
                ContestId: 0,
                LanguageBaseId: 11,
                Section: 'Home',
                SubSection: 0
            }
        });

        return sponsers;
    }
)



const homePageSlice = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase( fetchHomeBnners.fulfilled, ( state, action: PayloadAction<IState['sponsers']> )=> {
                state.sponsers = action.payload
            })
    }
);

export default homePageSlice;