import Route from "lib/router/Route";
import React from "react"
const AssistanceFund = React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/AssistanceFund"));
const FuneralCosts = React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/funeral_costs_forms/FuneralCosts"));
const MarriageForm = React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/marriage_forms/MarriageForm"));
const MedicalEquipment = React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/medical_equipment_form/MedicalEquipment"));
const MultipleTwinsCosts = React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/multiple_twins_costs/MultipleTwinsCosts"));
const EmployeeTuition =  React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/tuition_study_form/EmployeeTuition"));
const MedicalExpenses =  React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/medical_expenses_forms/MedicalExpenses"));
const DisabledPersonLoan =  React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/disabled_person_loan_forms/DisabledPersonLoan"));
const UnexpectedEvent =  React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/unexpected_event_forms/UnexpectedEvent"));
const Dental =  React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/dental_forms/Dental"));
const Nursing =  React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/nursing_forms/Nursing"));
const HousingDeposit =  React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/housing_deposit_forms/HousingDeposit"));
const StudentHousingDeposit =  React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/student_housing_deposit_forms_forms/StudentHousingDeposit"));
const FemaleHeaded =  React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/female_headed_forms/FemaleHeaded"));
const FinancialProblem =  React.lazy(()=>import ("components/request-forms/sandogh_hamyari_forms/employees/financial_problem_forms/FinancialProblem"));
const MadadKariForm =  React.lazy(()=>import ("components/request-forms/madadkari_forms/MadadKariForms"));
const RetNursingAllowance =  React.lazy(()=>import ("components/request-forms/madadkari_forms/nursing_allowance_forms/RetNursingAllowanceForm"));
const EmpNursingAllowance =  React.lazy(()=>import ("components/request-forms/madadkari_forms/nursing_allowance_forms/EmpNursingAllowanceForm"));
const RetAllowanceDisabilityChildren =  React.lazy(()=>import ("components/request-forms/madadkari_forms/allowance_disability_children_form/RetAllowanceDisabilityChildrenForm"));
const EmpAllowanceDisabilityChildren =  React.lazy(()=>import ("components/request-forms/madadkari_forms/allowance_disability_children_form/EmpAllowanceDisabilityChildrenForm"));
const IntroRetAccountForm =  React.lazy(()=>import ("components/request-forms/Intro_retirement_accounts/IntroRetAccountForm"));


type EmpOrRet = "employee" | "retired"
let empOrRet: EmpOrRet = "employee"
export const reqFormRoutes = {
    sandoghEmployee: new Route(`/requestForms/hamyari/employee`, "انتخاب فرم های کارکنان", AssistanceFund),
    sandoghRetired: new Route(`/requestForms/hamyari/retired`, "انتخاب فرم های بازنشستگان", AssistanceFund),
    empMarriageForm: new Route(`/requestForms/hamyari/employee/marriageform`, "ازدواج همکار/فرزند همکار" , MarriageForm),
    retMarriageForm: new Route(`/requestForms/hamyari/retired/marriageform`, "ازدواج همکار/فرزند بازنشسته" , MarriageForm),
    empTuitionStudyForm: new Route(`/requestForms/hamyari/employee/tuitionstudyform`, "هزینه تحصیل همکار/فرزند همکار", EmployeeTuition),
    retTuitionStudyForm: new Route(`/requestForms/hamyari/retired/tuitionstudyform`, "هزینه تحصیل همکار/فرزند بازنشسته", EmployeeTuition),
    funeralCostsForm: new Route(`/requestForms/hamyari/funeralcostsform`,  "هزینه کفن و دفن غیر تحت تکفل", FuneralCosts),
    multipleTwinsForm: new Route(`/requestForms/hamyari/multipletwinsform`, "هزینه فرزندان چندقلو", MultipleTwinsCosts),
    medicalEquipmentForm: new Route(`/requestForms/hamyari/medicalequipmentform`, "تجهیزات خاص پزشکی", MedicalEquipment),
    medicalExpensesForm: new Route(`/requestForms/hamyari/medicalexpensesform`, "هزینه های درمانی", MedicalExpenses),
    disabledPersonLoanForm: new Route(`/requestForms/hamyari/disablepersonloanform`, "توانخواه", DisabledPersonLoan),
    unexpectedEventForm: new Route(`/requestForms/hamyari/unexpectedeventform`, "حوادث غیرمترقبه", UnexpectedEvent),
    dental: new Route(`/requestForms/hamyari/dentalform`, "دندان پزشکی", Dental),
    nursing: new Route(`/requestForms/hamyari/nursingform`, "پرستاری", Nursing),
    housingDeposit: new Route(`/requestForms/hamyari/housingdepositform`, "ودیعه مسکن", HousingDeposit),
    studentHousingDeposit: new Route(`/requestForms/hamyari/studenthousingdepositform`, "ودیعه مسکن دانشجویی", StudentHousingDeposit),
    femaleHeaded: new Route(`/requestForms/hamyari/femaleheadedform`, "زنان سرپرست خانوار", FemaleHeaded),
    FinancialProblem: new Route(`/requestForms/hamyari/financialproblemform`, "مشکل مالی", FinancialProblem),
    madadkariEmpForm: new Route(`/requestForms/madadkari/employee`, "تعاون و مددکاری شاغلین", MadadKariForm),
    madadkariRetForm: new Route(`/requestForms/madadkari/retired`, "تعاون و مددکاری بازنشستگان", MadadKariForm),
    empNursingAllowanceForm: new Route(`/requestForms/madadkari/employee/nursingalllowanceform`, "کمک هزینه پرستاری شاغلین" , EmpNursingAllowance),
    retNursingAllowanceForm: new Route(`/requestForms/madadkari/retired/nursingalllowanceform`, "کمک هزینه پرستاری بازنشستگان" , RetNursingAllowance),
    empAllowanceDisabilityChildrenForm: new Route(`/requestForms/madadkari/employee/allowancedisabilityform`, "کمک هزینه فرزندان معلول شاغلین", EmpAllowanceDisabilityChildren),
    retAllowanceDisabilityChildrenForm: new Route(`/requestForms/madadkari/retired/allowancedisabilityform`, "کمک هزینه فرزندان معلول بازنشستگان", RetAllowanceDisabilityChildren),
    introRetAccountForm: new Route(`/requestForms/introretaccountform‍‍‍`, "معرفی حساب بازنشستگی", IntroRetAccountForm),
    
}