
import { createAsyncThunk, createReducer, PayloadAction } from '@reduxjs/toolkit';
import SOAP_request from 'lib/http-request/SOAP-request';

export interface IMessageDetails {
    Body: string
    Email: string 
    FullName: string
    Id: string
    ImageName: null|string
    IsActive: null|boolean
    IsMessage: boolean
    IsRead: boolean
    IsReplyRead: boolean
    IsStarred: null|boolean
    Phone: string
    ReceiverFullName: string|null
    RegisterDate: string
    ReplyDate: string|null
    ReplyText: null|string
    Subject: string|null
    UserId: null|string
}

interface IState {
    messages: IMessageDetails[],
    unreadMessagesCount: number,
    isLoaded: boolean
}

export const fetchUserMessages = createAsyncThunk(
    'userMessages/fetchUserMessages', 
    async () =>  {
        const { data: { QuestionReplies } } = await SOAP_request({
            soapAction: 'GetUserQuestions',
            method: 'POST',
        });

        return QuestionReplies;
    }
)

export const fetchUnreadMessagesCount = createAsyncThunk(
    'userMessages/fetchUnreadMessagesCount', 
    async () =>  {
        const { data: unReadMessagesCount } = await SOAP_request({
            soapAction: 'GetUnreadMessagesCount',
            method: 'POST',
        });

        return unReadMessagesCount;
    }
)

export const readMessages = createAsyncThunk(
    'userMessages/readMessages', 
    async (QuestionReplyId: string) =>  {
        await SOAP_request({
            soapAction: 'MarkMessageAsRead',
            method: 'POST',
            data: { QuestionReplyId }
        });

        return QuestionReplyId;
    }
)

const initialState = {
    messages: [],
    unreadMessagesCount: 0,
    isLoaded: false
};

const userMessagesSlice = createReducer<IState>(
    initialState,
    (builder) => {
        builder
            .addCase( fetchUserMessages.fulfilled, ( state, action: PayloadAction<IMessageDetails[]> )=> {
                state.messages = action.payload;
                state.isLoaded = true;
            })
            .addCase( fetchUnreadMessagesCount.fulfilled, ( state, action: PayloadAction<number> )=> {
                state.unreadMessagesCount = +action.payload
            })
            .addCase( readMessages.fulfilled, ( state, action: PayloadAction<string> )=> {
                const messageIndex = state.messages.findIndex( message=> String(message.Id) === String(action.payload) );
                state.messages[messageIndex].IsReplyRead = true;
                --state.unreadMessagesCount;
            })
    }
)

export default userMessagesSlice;