import { TextField, TextFieldProps } from "@material-ui/core";
import React from "react";
import { change, WrappedFieldProps } from "redux-form";
import { renderError } from "./lib";

export type RenderTextFieldProps = TextFieldProps & WrappedFieldProps & {
    maxLenght: number,
    onValueChange: (e: React.ChangeEvent)=> void
}

const RenderTextField: React.FC<RenderTextFieldProps> = ({
    input: { onChange, ...input },
    type, 
    label, 
    meta, 
    maxLenght,
    helperText,
    onValueChange,
    ...props})=> {
    const error = renderError(meta);

    const changeHandler = React.useCallback( function(e) {
        if(maxLenght && String(e.target.value).length > maxLenght) return;
        if(onValueChange) onValueChange(e);
        onChange(e);
    }, [onValueChange, maxLenght, onChange])

    return(
        <TextField
            label={label}
            type={type}
            color='primary'
            {...input}
            {...props}
            onChange={changeHandler}
            error={!!(error)}
            helperText={helperText||error}
        />
    )
}

export default RenderTextField;
