import React from 'react';
import Layout from 'components/layout';
import history from 'lib/router/history';
import SecretRoute from 'lib/router/SecretRoute';
import { Route, Router, Switch } from 'react-router';
import GlobalAlert from 'components/generic/GlobalAlert';
import Login from 'pages/Login';
import GlobalDialog from 'components/generic/GlobalDialog';
import { onMessageListener } from 'lib/firebase';
import ImageLightBox from 'components/generic/ImageLightBox';
import { useAppDispatch } from 'store';
import { fetchUnreadMessagesCount } from 'features/messages/userMessagesSlice';

const App = ()=> {

  const dispatch = useAppDispatch();

  // handle foreground notifications
  onMessageListener()
    .then(( payload: any )=> {
      console.log('Received foreground message ', payload);

      // fetch messages count
      dispatch( fetchUnreadMessagesCount() );

      // just for test ( will be changed later with UI Components instead browser notification )
      if( 'navigator' in window && 'serviceWorker' in navigator ) {
        navigator.serviceWorker.ready
        .then( swRegistration=> swRegistration.showNotification( payload.notification.title, {
          body: payload.notification.body,
          icon:  'https://pwa.tejaratfamily.ir/assets/icons/android-chrome-512x512.png',
          badge: 'https://pwa.tejaratfamily.ir/assets/icons/favicon-32x32.png',
          data: { ...payload.data, isCustom: true }
        } ) )
      }
    });
  
  return (
      <Router history={history}>
        <Switch>
          <Route path='/login' component={Login} />
          <SecretRoute path='/' component={Layout} />
        </Switch>
        <GlobalAlert />
        <GlobalDialog />
        <ImageLightBox />
      </Router>
  )
}

export default App;
