import Route from "lib/router/Route";
import React from "react";
import { contestTypes } from "utility/constants";
import { baseGroupOrContent, baseGroupOrContentDisplayName } from "utility/constants/baseGroupOrContent";
import { surveysGroups, surveysGroupsDisplayName } from "utility/constants/surveysGroups";
import { reqFormRoutes } from "./request_form_routes/RequestFormRoutes";
const Home = React.lazy(()=>import("pages/Home"));
const EditProfile = React.lazy(()=>import("pages/EditProfile"));
const Profile = React.lazy(()=>import("pages/Profile"));
const PageContent = React.lazy(()=>import("pages/PageContent"));
const UserMessages = React.lazy(()=>import("pages/UserMessages"));
const UserMessagesDetails = React.lazy(()=>import("pages/UserMessagesDetails"));
const Bases = React.lazy(()=>import("pages/Bases"));
const News = React.lazy(()=>import("pages/News"));
const NewsDetails = React.lazy(()=>import("pages/NewsDetails"));
const Publishers = React.lazy(()=>import("pages/Publishers"));
const BMICalculator = React.lazy(()=>import("pages/BMICalculator"));
const Suggestions = React.lazy(()=>import("pages/Suggestions"));
const BMRCalculator = React.lazy(()=>import("pages/BMRCalculator"));
const PhotoContest = React.lazy(()=>import("pages/PhotoContest"));
const PhotoContestPosts = React.lazy(()=>import("pages/PhotoContestPosts"));
const PhotoContestPostDetails = React.lazy(()=>import("pages/PhotoContestPostDetails"));
const OtherUsersProfile = React.lazy(()=>import("pages/OtherUsersProfile"));
const HealthCenters = React.lazy(()=>import("pages/HealthCenters"));
const Surveys = React.lazy(()=>import("pages/Surveys"));
const RequestForms = React.lazy(()=>import("pages/RequestForms"));
const MaddadKari = React.lazy(()=>import("pages/MaddadKari"));
const SandoghHamyari = React.lazy(()=>import("pages/SandoghHamyari"));
const QueuingSystem = React.lazy(()=>import("pages/QueuingSystem"));
const QueueReservDates = React.lazy(()=>import("pages/QueueReservDates"));
const QueueReservation = React.lazy(()=>import("pages/QueueReservation"));
const QueueUsersFamily = React.lazy(()=>import("pages/QueueUsersFamily"));
const OptionsList = React.lazy(()=>import("pages/OptionsList"));
const SearchingBranches = React.lazy(()=>import("pages/searching_branches/SearchingBranches"));
const ManageBranchList = React.lazy(()=>import("pages/searching_branches/ManageBranchList"))
const BranchSearchForm = React.lazy(()=>import("pages/searching_branches/BranchSearchForm"))
const BranchSearchedDetail = React.lazy(()=>import("pages/searching_branches/BranchSearchedDetail"))

/**
 * @description ALL PAGES DEFINED HERE
 * @example [name] = new Route<TParams|void>('/path*', 'title of page', Component),
 * @var routes
 */
const routes = {
    home: new Route('/', 'صفحه نخست', Home),
    profile: new Route('/profile', 'پروفایل', Profile),
    editProfile: new Route('/profile/edit', 'ویرایش پروفایل', EditProfile),
    pageContent: new Route<{ pageId: string, title?: string }>('/pageContent/:pageId/:title?', ({ match: { params: { title } } })=> title || 'محتوای عمومی', PageContent),
    suggestions: new Route('/suggestions', 'پیشنهادات', Suggestions),
    userMessages: new Route('/messages', 'پیام ها', UserMessages),
    userMessagesDetails: new Route<{ messageId: string, title?: string }>('/messages/:messageId/:title?', ({ match: { params: { title } } })=> title || "محتوای پیام", UserMessagesDetails),
    publishers: new Route('/publishers', 'انتشارات بانک', Publishers),
    bmiCalculator: new Route('/bmiCalculator', 'محاسبه BMI', BMICalculator),
    bmrCalculator: new Route('/bmrCalculator', 'محاسبه BMR', BMRCalculator),
    bases: new Route<{ parentId: string, title?: string }>('/bases/:parentId/:title?', ({ match: { params: { parentId, title } } })=> title || baseGroupOrContentDisplayName[parentId as baseGroupOrContent], Bases),
    news: new Route<{ baseId: string, title?: string }>('/basesNews/:baseId/:title?', ({ match: { params: { baseId, title } } })=> title || baseGroupOrContentDisplayName[baseId as baseGroupOrContent], News),
    newsDetails: new Route<{ newsId: string, title?: string }>('/news/:newsId/:title?', ({ match: { params: { title } } })=> title || "محتوای خبر", NewsDetails),
    photoContest: new Route<{ type: contestTypes }>('/photoContest/:type', 'ارسال عکس', PhotoContest),
    photoContestPosts: new Route<{ contestId: string, title?: string }>('/photoContest/:contestId/:title?', ({ match: { params: { title } } })=> title || "پست ها", PhotoContestPosts),
    photoContestPostDetails: new Route<{ contestId: string, postId: string, userId: string, title?: string }>('/photoContest/:contestId/posts/:postId/:userId/:title?', ({ match: { params: { title } } })=> title || "جزئیات پست", PhotoContestPostDetails),
    otherUsersProfile: new Route<{ userId: string }>('/otherUsersProfile/:userId', 'اطلاعات کاربری', OtherUsersProfile),
    healthCenters: new Route('/healthCenters', 'مراکز درمانی', HealthCenters),
    surveys: new Route<{ groupId: string, title?: string }>('/surveys/:groupId/:title?', ({ match: { params: { groupId, title } } })=> title || surveysGroupsDisplayName[groupId as surveysGroups] || 'نظرسنجی', Surveys),
    requestForms: new Route('/requestForms', ({ match: { params: { title } } })=> title || "فرم های درخواست", RequestForms),
    maddadKari: new Route('/requestForms/maddadkari', ({ match: { params: { title } } })=> title || "امور مدد کاری", MaddadKari),
    sandoghHamyari: new Route('/requestForms/hamyari', ({ match: { params: { title } } })=> title || "صندوق همیاری", SandoghHamyari),
    ...reqFormRoutes,
    optionsList: new Route('/optionsList', "لیست خدمات", OptionsList),
    queuingSystem: new Route('/queuingSystem', ({ match: { params: { title } } })=> title || "سامانه نوبت دهی", QueuingSystem),
    queueReservDates: new Route<{ complexId: string,title: string }>('/queuingSystem/:complexId/:title', ({ match: { params: { title } } })=> title || "رزرو", QueueReservDates),
    queueReservation: new Route<{ complexId: string, title: string, date: string }>('/queuingSystem/:complexId/:title/:date', ({ match: { params: { title } } })=> title || "رزرو", QueueReservation),
    queueUsersFamily: new Route<{ complexId: string, title: string, date: string, timesheetId: string }>('/queuingSystem/:complexId/:title/:date/reservForFamily', "رزرو برای افراد تحت تکفل", QueueUsersFamily),
    searchingBranches: new Route('/searchingBranches', "شعب و واحد ها", SearchingBranches),
    branchSearchForm: new Route('/searchingBranches/branchForm', "جست و جوی شعب", BranchSearchForm),
    branchSearchedDetail: new Route('/searchingBranches/branchSearchdDetail', "جزییات شعبه", BranchSearchedDetail),
    manageBranchList: new Route<{groupId: string, title?: string}>('/searchingBranches/:groupId/:title?', ({ match: { params: { title } } })=> title || "شعب و واحد ها", ManageBranchList),
};

export default routes;