// base contents and superbase contents HARD CODE (News)
export enum surveysGroups {
    contest = '6',
    syrvey = '3',
}

export const surveysGroupsDisplayName = {
    [surveysGroups.contest]: 'مسابقات',
    [surveysGroups.syrvey]: 'نظرسنجی',

}
