import { createAsyncThunk, createReducer, PayloadAction, createAction } from "@reduxjs/toolkit"
import REST_APICall, { REST_APICallV2 } from "lib/http-request/REST-request"
import { useAppSelector } from "store";


export interface DeputesType {
    Address: any,
    City: number,
    Fax: boolean,
    Name: string,
    RelationalCode: number,
    row: number,
    Tel: string,
}


export const fetchDeputes = createAsyncThunk(
    'searchingBranches/fetchDeputes',
    async function() {
        const { data: { Result: branchesList } } = await REST_APICallV2({
            domain: 'newApi',
            url: '/GetDeputes?q=',
            method: 'GET',
            withCredentials: false
        });        
        return  {branchesList} ;
    }
)

export interface BrancheManagementType {
    Address: any,
    City: number,
    Fax: boolean,
    ManagementName: string,
    RelationalCode: number,
    row: number,
    Tel: string,
}

export const fetchBrancheManagement = createAsyncThunk(
    'searchingBranches/fetchBrancheManagement',
    async function() {
        const { data: { Result: branchesList } } = await REST_APICallV2({
            domain: 'newApi',
            url: '/GetBranchManagements?q=',
            method: 'GET',
            withCredentials: false
        });  
        console.log(branchesList);
              
        return  {branchesList} ;
    }
)
export interface SearchBranchParamType{
    Town: string,
    BranchName: string,
    BranchCode: string,
    City: string,
    Address: string
}
export interface SearchedBranchType{
    BranchId: number,
    BranchCode: number,
    ParentBranchCode: number,
    BranchName: string,
    ManagementName: string,
    Town: string,
    Weeklyworkinghours: string,
    WeeklyworkinghoursAfternoon: string,
    Thursdayworkinghours: string,
    ATM: boolean,
    AfternoonExchange: boolean,
    Pos: boolean,
    InstantCardIssuance: boolean,
    SafeBox: boolean,
    StockExchange: boolean,
    RationalATM: boolean,
    Currencycircle: boolean,
    TownId: number,
    City: string,
    Address: string,
    PreTel: string,
    Tel1: string,
    Tel2: string,
    Tel3: string,
    Tel4: string,
    PostalCode: string
}

export const fetchBrancheSearch = createAsyncThunk(
    'searchingBranches/fetchBrancheSearch',
    async function(searchFilters: SearchBranchParamType) {
        const { data: { Result: Branches } } = await REST_APICallV2({
            domain: 'newApi',
            url: '/GetBranchs',
            method: 'GET',
            withCredentials: false,
            params: {
                ...searchFilters
              }
        });
        const searchedBranches = Branches.Branchs                                
        return searchedBranches;
    }
)

export interface ProvinceType {
    id: number,
    code: string,
    name: string,
    centerV2s: any,
    cityV2s: any
}

export const fetchProvince = createAsyncThunk(
    'searchingBranches/fetchProvince',
    async function() {
        const { data: { result: province } } = await REST_APICall({
            domain: 'amaken',
            url: '/Province/GetProvince',
            method: 'GET',
            withCredentials: false
        });                
        return  {province} ;
    }
)

export const showSelectedBranch = createAction<SearchedBranchType>('searchingBranches/showSelectedBranch')

export interface CityType {
    id: number,
    code: string,
    name: string,
    provinceId: number,
    province: any
    centerV2s: any,
}

export const fetchCities = createAsyncThunk(
    'searchingBranches/fetchCities',
    async function(provinceId: string) {
        const { data: { result: city } } = await REST_APICall({
            domain: 'amaken',
            url: '/City/GetProvinceCities',
            method: 'GET',
            params: { id: provinceId }
        });
        return {city};
    }
)

export interface SearchingBranchesState {
    branchesList: BrancheManagementType[] | DeputesType[],
    province: ProvinceType[],
    city: CityType[],
    searchedBranches: SearchedBranchType[],
    selectedBranch: SearchedBranchType
}

const initialState = {
    branchesList: [],
    province: [],
    city: [],
    searchedBranches: [],
    selectedBranch: {
        BranchId: 0,
        BranchCode: 0,
        ParentBranchCode: 0,
        BranchName: "",
        ManagementName: "",
        Town: "",
        Weeklyworkinghours: "",
        WeeklyworkinghoursAfternoon: "",
        Thursdayworkinghours: "",
        ATM: false,
        AfternoonExchange: false,
        Pos: false,
        InstantCardIssuance: false,
        SafeBox: false,
        StockExchange: false,
        RationalATM: false,
        Currencycircle: false,
        TownId: 0,
        City: "",
        Address: "",
        PreTel: "",
        Tel1: "",
        Tel2: "",
        Tel3: "",
        Tel4: "",
        PostalCode: ""
    }
}

const SearchingBranchesSlice = createReducer<SearchingBranchesState>(
    initialState,
    (builder)=>{
        builder
        .addCase(fetchDeputes.fulfilled, (state, action: PayloadAction<any>)=>{
            const{branchesList} = action.payload;
            state.branchesList = branchesList;
        })
        .addCase(fetchBrancheManagement.fulfilled, (state, action: PayloadAction<any>)=>{
            const{branchesList} = action.payload;
            state.branchesList = branchesList;
        })
        .addCase(fetchBrancheSearch.fulfilled, (state, action: PayloadAction<any>)=>{
            const searchedBranches = action.payload;
            state.searchedBranches = searchedBranches;
        })
        .addCase(fetchProvince.fulfilled, (state, action: PayloadAction<any>)=>{
            const {province} = action.payload;
            state.province = province;
        })
        .addCase(fetchCities.fulfilled, (state, action: PayloadAction<any>)=>{
            const {city} = action.payload;
            state.city = city;
        })
        .addCase(showSelectedBranch, (state, action: PayloadAction<any>)=>{
            const selectedBranch = action.payload;
            console.log(action);
            
            state.selectedBranch = selectedBranch;
        })
    }
)
export default SearchingBranchesSlice