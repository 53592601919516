import { Container, Grid, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { Redirect } from 'react-router';
import ResetPasswordForm from './ResetPasswordForm';
import PhoneNubmerInput from './PhoneNubmerInput'; 
import Text from 'components/styled-components/Text';
import resetPasswordPhases from 'utility/constants/resetPasswordPhases';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import history from 'lib/router/history';
import SOAP_request from 'lib/http-request/SOAP-request';
import { showAlert } from 'features/alert/alertSlice';

const useStyles = makeStyles(theme => ({
    FieldsContainer: {
        [theme.breakpoints.up('md')]: {
            height: '100vh',
        },
        padding: theme.spacing(3,2,0)
    },
    header: {
        margin: theme.spacing(2,0)
    }
}))

function ResetPassword() {
    const classes = useStyles(),
    [phase, setPhase] = React.useState<resetPasswordPhases>(resetPasswordPhases.ENTER_PHONE_NUMBER),
    [phoneNumber, setPhoneNumber] = React.useState<string>(''),
    dispatch = useAppDispatch(),
    isUserLoggedIn = useAppSelector( state=> state.user.isLogin );
    
    const onPhoneSubmit = React.useCallback( async function(MobileNo) {

        try {
            await SOAP_request({
                method: 'POST',
                soapAction: 'ResetPasswordAttempt',
                data: { MobileNo },
            });
    
            setPhoneNumber(MobileNo);
            setPhase(resetPasswordPhases.RESET_PASSWORD);

        } catch {
            dispatch(
                showAlert({
                    message: 'شماره تلفن وارد شده ثبت نشده است',
                    severity: 'error'
                })
            )
        }
        
    }, [dispatch]);

    function renderPhase() {
        switch(phase) {
            case resetPasswordPhases.ENTER_PHONE_NUMBER:
                return <PhoneNubmerInput initialValues={{ phoneNumber }} onPhoneSubmit={onPhoneSubmit} />
            case resetPasswordPhases.RESET_PASSWORD:
                return <ResetPasswordForm phoneNumber={phoneNumber} changePhase={setPhase} sendCodeAgain={onPhoneSubmit}  />
        }
    }

    if(isUserLoggedIn) return <Redirect to='/'/>

    return (
        <Container maxWidth='xs'>
            <Grid container spacing={1} className={classes.FieldsContainer} alignContent='center'>
                <Grid item container alignItems='center' justifyContent='space-between' xs={12} className={classes.header}>
                    <Text variant='subtitle1'>
                        بازیابی رمز عبور
                    </Text>
                    <IconButton size='medium' onClick={()=> history.replace('/login')}>
                        <HiOutlineArrowLeft />
                    </IconButton>
                </Grid>
                { renderPhase() }
            </Grid>
        </Container>
    )
}

export default ResetPassword;
