import { createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit";
import REST_APICall from "lib/http-request/REST-request";

export interface ISurveyDetails {
    DefScore: number;
    Description: string;
    Id: string;
    IsPublic: string;
    QuesScore: string;
    RegDate: string;
    Title: string;
}
export interface ISurveySliceState {
    surveys: { [groupId: string]: ISurveyDetails[] }
}

export const fetchSurveys = createAsyncThunk(
    'survays/fetchSurveysByGroupId',
    async function(groupId: string) {
        const { data: surveys } = await REST_APICall({
            domain: 'survey',
            url: '/GetSurveysByGroupId',
            method: 'GET',
            params: { Id: groupId }
        });

        return { groupId, surveys };
    }
)

const initialState: ISurveySliceState = {
    surveys: {}
};

const surveySlice = createReducer<ISurveySliceState>(
    initialState,
    (builder) => {
        builder
            .addCase( fetchSurveys.fulfilled, ( state, action: PayloadAction<any> )=> {
                const { groupId, surveys } = action.payload;
                state.surveys[groupId] = surveys;
            })
    }
);

export default surveySlice;