import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Btn from 'components/styled-components/Btn';
import { clearDialogPack, closeDialog, dialogState, TDialogPack } from 'features/dialog/dialogSlice';
import React, { useCallback } from 'react';
import { FC, useEffect, useState } from 'react';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import { RootState, useAppDispatch } from 'store';


interface propsFromStore {
    dialogPack: TDialogPack,
    open: boolean
}

const useStyle = makeStyles( theme=> ({
    root: {
        direction: 'ltr',
    },
    paper: {
        direction: 'rtl'
    },
    componentContent: {
        '& .MuiDialogContent-root': {
            overflow: 'hidden !important',
            padding: '0 !important',
        },
    },
    paperScrollBody: {
        marginTop: 40,
        marginBottom: 80,
        textAlign: 'right',
        [theme.breakpoints.only('xs')]: {
            maxWidth: '90% !important',
            width: '90% !important',
            marginRight: 'auto !important',
            marginLeft: 'auto !important'
        }
    },
    actions: {
        '& button': {
            fontFamily: 'sahel'
        }
    },
    closeIcon: {
        position: 'absolute',
        margin: theme.spacing(1),
        left: 0
    },
    header: {
        padding: theme.spacing(2,7)
    },
    componentContentPaper: {
        '& .MuiDialogContent-root': {
            overflowX: 'hidden !important',
            padding: '0 !important',
        },
    }
}))

const GlobalDialog: FC<propsFromStore> = ({ dialogPack, open })=> {
    const [dialogInfo, setDialogInfo] = useState<dialogState>(),
    dispatch = useAppDispatch(),
    handleClose = useCallback(() => {
        dispatch(closeDialog());
    }, [dispatch]),
    closeFirst = (callBack: Function)=> ()=>{
        callBack(); 
        handleClose();
    },
    afterCloseHandler = () => setDialogInfo(undefined),
    classes = useStyle();
    useEffect(() => {
        if (dialogPack.length && !dialogInfo) {
          setDialogInfo({ ...dialogPack[0] });
          dispatch(clearDialogPack());
        } else if (dialogPack.length && dialogInfo && open) {
            handleClose();
        }
    }, [dialogPack, dialogInfo, open, dispatch,handleClose]);

    return (
        <Dialog
            fullScreen={dialogInfo?.fullScreen || false}
            open={open}
            onClose={handleClose}
            fullWidth={typeof dialogInfo?.fullWidth !== 'undefined'? dialogInfo?.fullWidth : true}
            maxWidth={typeof dialogInfo?.maxWidth !== 'undefined'? dialogInfo?.maxWidth : 'xs'}
            scroll={dialogInfo?.scroll || 'body'}
            dir='rtl'
            className={classes.root}
            classes={{
                paperScrollBody: clsx(classes.paperScrollBody,typeof dialogInfo?.body !== 'string' && classes.componentContent),
                paperScrollPaper: (typeof dialogInfo?.body !== 'string' && classes.componentContentPaper) || undefined,
                paper: classes.paper
            }}
            key={dialogInfo?.key}
            TransitionProps={{ onExited: afterCloseHandler }}
        >
          {
              dialogInfo?.fullScreen &&  (
                <IconButton className={classes.closeIcon} onClick={handleClose} title='بستن'>
                    <HiOutlineChevronLeft />
                </IconButton>
              )
          }
          {dialogInfo?.title&&(
            <DialogTitle disableTypography dir='rtl'>
                <Typography variant='h6' align={dialogInfo.titleAlign||'right'}>
                    {dialogInfo.title}
                </Typography>
            </DialogTitle>
          )}
          {dialogInfo?.body&&
          <DialogContent >
            {typeof dialogInfo.body === 'string' ? (
                <DialogContentText>{dialogInfo.body}</DialogContentText>
            ):(
                React.cloneElement(dialogInfo.body)
            )}
          </DialogContent>}
          { !dialogInfo?.noAction&&
            <DialogActions>
                    <Btn 
                        onClick={dialogInfo?.onCancel?closeFirst(dialogInfo.onCancel):handleClose} 
                        color={dialogInfo?.cancelColor || 'primary'} 
                        size='small'
                    >
                        {dialogInfo?.cancelText || 'لغو'}
                    </Btn>
                { dialogInfo?.onOk&& 
                    <Btn 
                        onClick={closeFirst(dialogInfo.onOk)} 
                        color={dialogInfo.okColor || 'primary'}
                        size='small'
                    >
                        { dialogInfo.okText || 'قبول' }
                    </Btn>
                }
            </DialogActions>
          }       
      </Dialog>
    );
};

const mapStateToProps = ( { globalDialog: {dialogPack, open}}: RootState)=> ({
    dialogPack,
    open
})

export default connect<
    propsFromStore,
    null,
    {},
    RootState
>(mapStateToProps)(GlobalDialog);
