import { createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { IUserInfo, IUserToken } from "features/user/userSlice";
import { mapkeys } from "lib";
import globalStorage from "lib/globalStorage";
import SOAP_request from "lib/http-request/SOAP-request";
import globalStorageItems from "utility/constants/globalStorageItems";

export interface IContestDetails {
    ContestId: string;
    RefereeUserId: string;
    DeadlineDate: string; 
    ConfirmedCount: number;
    PhotoName: string; 
    PhotosCount: string; 
    SeenPhotosCount: string; 
    IsActive: 0|1; 
    Title: string;
    Description: string;
}

export interface IPhotoContestPostDetails {
    PhotoContestId: string,
    ContestId: string,
    RefereeUserId: string,
    LanguageBaseId: string,
    ContestTitle: string,
    ContestDescription: string,
    DeadlineDate: string,
    IsActive: boolean,
    PhotoId: string,
    PhotographerUserId: string,
    PhotographerUserTitle: string,
    PhotographerPhotoName: string,
    DateTime: string,
    GPSLocation: string,
    PhotoPublishedDate: string,
    RefereeScore: string,
    RefereeComment: string,
    IsPrivate: boolean,
    extension: string
    ThumbImage: string,
    IsConfirm: boolean,
    IsWinner: boolean,
    Version: string,
    CreatedDate: string,
    UpdatedDate: string,
    Comment: string,
    Hashtag: string,
    LikeCount: number,
    CommentCount: string,
    ViewCount: string,
    MyLiked: string,
    RowNum: string
}

export interface IPostComments {
    PhotoCommentId: string, 
    PhotoId: string, 
    UserId: string, 
    UserTitle: string, 
    PhotoName: string, 
    CreatedDate: string,
    Comment: string
}
export interface IPhotoContestState {
    expiredContests: IContestDetails[]|null,
    currentContests: IContestDetails[]|null,
    posts: { [contestId: string]:  { [postId: string]: IPhotoContestPostDetails } },
    photoGraphers: { [userId: string]: IUserInfo },
    photoGraphersPosts: { [userId: string]: IPhotoContestPostDetails[] },
    postsComments: { [postId: string]: IPostComments[] },
}

export const fetchCurrentPhotoContest = createAsyncThunk(
    'photoContest/current', 
    async () =>  {
        const { data: { VContest: currentContests } } = await SOAP_request({
            method: 'POST',
            soapAction: 'GetCurrentContest',
            data: {
                LanguageBaseId: 11,
                RefereeUserId: 0,
            }
        });

        return currentContests;
})
export const fetchExpiredPhotoContest = createAsyncThunk(
    'photoContest/expired', 
    async () =>  {
        const { data: { VContest: expiredContests } } = await SOAP_request({
            method: 'POST',
            soapAction: 'GetExpiredContest',
            data: {
                LanguageBaseId: 11,
                RefereeUserId: 0,
            }
        });

        return expiredContests;
});


export const fetchPhotoContestPosts = createAsyncThunk(
    'photoContest/getPhotos', 
    async (ContestId: string) =>  {
        const { data: { VPhotoContest: Posts } } = await SOAP_request({
            soapAction: 'GetPhotos',
            method: 'POST',
            data: { 
                LanguageBaseId: 11,
                PhotographerUserId: 0,
                ContestId,
                PhotoId: '',
                IsConfirm: true,
                IsWinner: '',
                Keyword: '',
                FromRecord: 0,
                PageSize: 100,
                OrderBy: 'date',
                RefereeUserId: 0,
                PhotoContestId: 0
            }
        });

        return { ContestId, Posts: Posts || [] };
    }
);

export const fetchPhotoContestPostComments = createAsyncThunk(
    'photoContest/getPhotosComments', 
    async ( PostId: string ) =>  {
        const { data: { VPhotoComment: Comments } } = await SOAP_request({
            method: 'POST',
            soapAction: 'GetPhotoComments',
            data: {
                LanguageBaseId: 11,
                PhotoId: PostId,
                PageSize: 100,
                FromRecord: 0,
            }
        });

        return { PostId, Comments };
});

export const fetchPhotoContestPostPhotoGrapherInfo = createAsyncThunk(
    'photoContest/getPhotosPhotoGrapher', 
    async ( { UserId }: any ) =>  {
        const { data: { VUser: [ UserInfo ] } } = await SOAP_request({
            method: 'POST',
            soapAction: 'GetUsersProfile',
            data: {
                LanguageBaseId: 11,
                OtherUserId: globalStorage.getItem<IUserToken>( globalStorageItems.USER_TOKEN )?.SESSION_ID,
                UserId
            }
        });

        return { UserId, UserInfo };
});

export const fetchPhotoContestPostPhotoGrapherPosts = createAsyncThunk(
    'photoContest/photoContestPostPhotoGrapherPosts', 
    async (UserId: string) =>  {
        const { data: { VPhotoContest: Posts } } = await SOAP_request({
            soapAction: 'GetPhotos',
            method: 'POST',
            data: { 
                LanguageBaseId: 11,
                PhotographerUserId: UserId,
                ContestId: 0,
                PhotoId: '',
                IsConfirm: true,
                IsWinner: '',
                Keyword: '',
                FromRecord: 0,
                PageSize: 100,
                OrderBy: 'date',
                RefereeUserId: 0,
                PhotoContestId: 0
            }
        });

        return { UserId, Posts: Posts || [] };
    }
);

export const togglePhotoContestPostLike = createAsyncThunk(
    'photoContest/togglePhotoContestPostLike', 
    ( { ContestId, IsLiked, PhotoGrapherUserId, PostId }: any ) =>  {
        SOAP_request({
            method: 'POST',
            soapAction: 'PhotoLikeAsync',
            data: {
                LanguageBaseId: 11,
                IsLiked,
                PhotoGrapherUserId,
                PhotoId: PostId
            }
        });

        return { ContestId, PostId, IsLiked };
});

const initialState: IPhotoContestState = {
    expiredContests: null,
    currentContests: null,
    posts: {},
    photoGraphers: {},
    photoGraphersPosts: {},
    postsComments: {},
}

const photoContestSlice = createReducer<IPhotoContestState>(
    initialState,
    (builder) => {
        builder
            .addCase( fetchCurrentPhotoContest.fulfilled, ( state, action: PayloadAction<IContestDetails[]> )=> {
                state.currentContests = action.payload;
            })
            .addCase( fetchExpiredPhotoContest.fulfilled, ( state, action: PayloadAction<IContestDetails[]> )=> {
                state.expiredContests = action.payload;
            })
            .addCase( fetchPhotoContestPosts.fulfilled, ( state, action: PayloadAction<any> )=> {
                const { ContestId, Posts } = action.payload;
                state.posts[ContestId] = mapkeys(Posts, 'PhotoId');
            })
            .addCase( fetchPhotoContestPostPhotoGrapherPosts.fulfilled, ( state, action: PayloadAction<any> )=> {
                const { UserId, Posts } = action.payload;
                state.photoGraphersPosts[UserId] = Posts;
            })
            .addCase( fetchPhotoContestPostComments.fulfilled, ( state, action: PayloadAction<any> )=> {
                const { PostId, Comments } = action.payload;
                state.postsComments[PostId] = Comments;
            })
            .addCase( fetchPhotoContestPostPhotoGrapherInfo.fulfilled, ( state, action: PayloadAction<any> )=> {
                const { UserId, UserInfo } = action.payload;
                state.photoGraphers[UserId] = UserInfo;
            })
            .addCase( togglePhotoContestPostLike.fulfilled, ( state, action: PayloadAction<any> )=> {
                const { ContestId, PostId, IsLiked } = action.payload;
                state.posts[ContestId][PostId].MyLiked = IsLiked;
                state.posts[ContestId][PostId].LikeCount = +state.posts[ContestId][PostId].LikeCount + (IsLiked ? 1 : -1)
            })
    }
);

export default photoContestSlice;