import { ComponentType, FC } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { RootState } from "store";

interface propsFromStore {
  isLogin: boolean,
}

interface ownProps {
  component: ComponentType<any>,
}

type SecretRouteProps = ownProps & RouteProps & propsFromStore;

const SecretRoute: FC<SecretRouteProps> = ({ component: Component, isLogin, ...props }) => (
    <Route
      {...props}
      render={ (componentProps) => { 
        return(
          isLogin ? (
              <Component {...componentProps} />
          ) : (
              <Redirect to='/login' />
          )
      )}}
    />
  );

const mapStatetoProps = (state: RootState)=> ({
    isLogin: state.user.isLogin
});

 export default connect<
  propsFromStore,
  null,
  ownProps,
  RootState
 >(mapStatetoProps)(SecretRoute);
