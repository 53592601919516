import { Checkbox, IconButton, InputAdornment, makeStyles, Typography } from "@material-ui/core";
import { Container, FormControlLabel, Grid } from "@material-ui/core";
import { FC, useState } from "react";
import { reduxForm, Form, InjectedFormProps, Field } from "redux-form";
import { HiEye, HiEyeOff } from "react-icons/hi";
import Btn from "./styled-components/Btn";
import RenderTextField from "./form-fields/RenderTextField";
import Text from "./styled-components/Text";
import RouterLink from "./styled-components/RouterLink";

interface OwnProps {
    onFormSubmit: (formValues:any)=> void
}

export interface LoginFormValues {
    username: string,
    password: string,
    rememberMe: boolean
}

type LoginFormProps = OwnProps & InjectedFormProps<LoginFormValues, OwnProps>;

const useStyle = makeStyles( theme=> ({
    FieldsContainer: {
        [theme.breakpoints.up('md')]: {
            height: '100vh',
        },
        padding: theme.spacing(3,2,0)
    },
    backwardIcon: {
        marginLeft: -12
    }
    
}));

export const RenderCheckbox = ({input , label}: any) => {
    return(
        <FormControlLabel 
            control={<Checkbox{...input} />}
            label={<Typography variant="body2">{label}</Typography>}
        />
    )
}

const LoginForm: FC<LoginFormProps> = ({ handleSubmit, submitting, onFormSubmit })=> {

    const classes = useStyle();
    const [showPass, setShowPass] = useState<boolean>(false);
    const onSubmit = async(formValues: LoginFormValues) => {
        await onFormSubmit(formValues);
    }

    return (
        <Container maxWidth='xs' component={Form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={4} className={classes.FieldsContainer} dir='rtl' alignContent='center'>
                <Grid item container alignItems='center' justifyContent='space-between' xs={12}>
                    <Text variant='subtitle1'>
                        ورود به پنل کاربری خانواده تجارت
                    </Text>
                    {/* <IconButton size='medium' className={classes.backwardIcon} onClick={()=> dispatch(setUserType(null))}><HiOutlineArrowLeft /></IconButton> */}
                </Grid>
                <Grid item xs={12}>
                    <Field 
                        type="text"
                        label="نام کاربری / شماره موبایل" 
                        dir='ltr'
                        name="username" 
                        fullWidth
                        inputProps={{
                            className: 'space-between-letters'
                        }}
                        variant='outlined'
                        color='primary'
                        required
                        component={RenderTextField} 
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field 
                        type={showPass ? 'text' : 'password'} 
                        label="گذرواژه" 
                        name="password" 
                        fullWidth
                        dir='ltr'
                        variant='outlined'
                        color='primary'
                        required
                        InputProps={{
                            endAdornment: ( 
                            <InputAdornment position="end"> 
                                <IconButton onClick={() => setShowPass( pv => !pv )}>
                                    {showPass ? <HiEye /> : <HiEyeOff />}  
                                </IconButton> 
                            </InputAdornment>
                            ),
                        }}
                        component={RenderTextField} 
                    />
                </Grid>
                <Grid item container justifyContent='space-between' alignItems='center' >
                    <Grid item>
                        <Field 
                            type="checkbox" 
                            label="مرا به خاطر بسپار" 
                            name="rememberMe" 
                            component={RenderCheckbox} 
                        />
                    </Grid>
                    <Grid item> 
                        <RouterLink variant="caption" to='login/reset-password' >
                            رمز عبورتان را فراموش کرده اید؟
                        </RouterLink>
                    </Grid>
                </Grid>
                <Grid item xs={12}> 
                    <Btn 
                        loading={submitting} 
                        variant='contained'
                        color='primary' 
                        fullWidth 
                        size='large' 
                        type="submit"
                    > 
                        ورود 
                    </Btn>
                </Grid>
            </Grid>
        </Container>
    );

    }

const validate = (formValues: LoginFormValues) => {
    const errors: any = {}
    if(!formValues.username){
        errors.username = 'نام کاربری خود را وارد کنید'
    }
    if(formValues.username && formValues.username.length < 3){
        errors.username = 'حداقل تعداد کاراکتر مورد قبول 3 کاراکتر میباشد'
    }
    if(formValues.username && formValues.username.includes(' ')){
        errors.username = 'استفاده از فاصله در نام کاربری مجاز نیست'
    }
    if(!formValues.password){
        errors.password = 'گذرواژه خود را وارد کنید'
    }
    if(formValues.password && formValues.password.length < 3){
        errors.password = 'حداقل تعداد کاراکتر مورد قبول 4 کاراکتر میباشد'
    }
    if(formValues.password && formValues.password.includes(' ')){
        errors.password = 'استفاده از فاصله در گذرواژه مجاز نیست'
    }
    return errors;
}

export default reduxForm<LoginFormValues, OwnProps>({
    form : 'login',
    validate,
    initialValues: { rememberMe: true }
})(LoginForm);
