/*************** mteamtest (test) ******************/
// export const MAIN_DOMAIN = 'http://tejaratfamily.mteamtest.ir';
// export const AMAKEN_DOMAIN = 'http://spaamaken.tejaratfamily.mteamtest.ir';
// export const SURVEY_DOMAIN = 'http://survey.tejaratfamily.mteamtest.ir';
// export const Static_FORM_DOMAIN = 'http://staticforms.mteamtest.ir';
// export const Complex_DOMAIN = 'http://tejaratcomplex.mteamtest.ir';
// export const NEW_API_DOMAIN = 'http://api.tejaratfamily.mteamtest.ir';
/*************** mteamtest (test) ******************/

/*************** tejaratFamily (real) ***************/
export const MAIN_DOMAIN = 'https://panel.tejaratfamily.ir';
export const AMAKEN_DOMAIN = 'https://amaken.tejaratfamily.ir';
export const SURVEY_DOMAIN = 'https://survey.tejaratfamily.ir';
export const Complex_DOMAIN = 'https://complex.tejaratfamily.ir';
export const Static_FORM_DOMAIN = 'https://forms.tejaratfamily.ir';
export const NEW_API_DOMAIN = 'https://api.tejaratfamily.ir';
/*************** tejaratFamily (real) ***************/

class LocationManager {
    public static SOAP_SERVICE = `${MAIN_DOMAIN}/photocontestws.asmx`;
    public static REST_API = {
        amaken: `${AMAKEN_DOMAIN}/api`,
        survey: `${SURVEY_DOMAIN}/api/Survey`,
        staticForm: `${Static_FORM_DOMAIN}`, //for test form in mteamtest server and do not know the real api for tejartfamily server
        complex: `${Complex_DOMAIN}/api/complex`,  //for test queuing in mteamtest server and do not know the real api for tejartfamily server
        newApi: `${NEW_API_DOMAIN}/api/PicContestApi/V2`,
    };

    public static imageDefaultType:string = ".jpg" ;
    
    /**Returns the online location for this image*/
    public static userImage(FileName:string,BinaryFileName:string|null=null):string
    {
        if(BinaryFileName!=null && BinaryFileName!=='')
        {
            return this.ImageBinaryLocation(BinaryFileName);
        }
        return this.ImageLocation("/UploadImages/userProfile/",FileName)
    }
    
    /**Returns the online location for this image*/
    public static commentFile(FileName:string):string
    {
        return this.ImageLocation("/uploadimages/commentFiles/",FileName);
    }
    
    /**Returns the image for GetPageData service*/
    public static pageImage(FileName:string):string
    {
        return this.ImageLocation("/UploadImages/PageContent/",FileName);
    }
    
    /**Returns the contests images location. ( It is not used yet )*/
    public static contestImage(FileName:string):string
    {
        return this.ImageLocation("/UploadImages/contest/",FileName);
    }
    
    /**Return ssposorImage*/
    public static sponsorImage(FileName:string):string
    {
        return this.ImageLocation("/uploadimages/sponsors/",FileName);
    }
    
    /**Return ssposor thumbnail*/
    public static sponsorThumb(FileName:string):string
    {
        return this.ImageLocation("/uploadimages/sponsors/thumb/",FileName);
    }
    
    /**Returns the uploaded image in real size*/
    public static imageReal(FileName:string):string
    {
        return this.ImageLocation("/UploadImages/original/",FileName);
    }
    
    /**Returns the uploaded MEDIA*/
    public static mediaFile(FileName:string,extention:string):string
    {
        return this.ImageLocation("/UploadImages/original/",FileName,extention);
    }
    
    /**Returns the uploaded image in real size*/
    public static imageThumb(FileName:string):string
    {
        return this.ImageLocation("/UploadImages/thumb/",FileName);
    }
    
    
    public static newsImage(FileName:string,extention:string=this.imageDefaultType,BinaryFileName:string|null=null):string
    {
        if(BinaryFileName!=null && BinaryFileName!=='')
        {
            return this.ImageBinaryLocation(BinaryFileName);
        }
        return this.ImageLocation("/UploadImages/News/",FileName,extention);
    }
    
    public static superBaseImage(FileName:string,extention:string=this.imageDefaultType,BinaryFileName:string|null=null):string
    {
        if(BinaryFileName!=null && BinaryFileName!=='')
        {
            return this.ImageBinaryLocation(BinaryFileName);
        }
        return this.ImageLocation("/UploadImages/superbases/",FileName,extention);
    }
    
    public static newsImageThum(FileName:string,extention:string=this.imageDefaultType,BinaryFileName:string|null=null):string
    {
        if(BinaryFileName!=null && BinaryFileName!=='')
        {
            return this.ImageBinaryLocation(BinaryFileName);
        }
        return this.ImageLocation("/UploadImages/News/thumb/",FileName,extention);
    }
    
    public static superBaseImageThum(FileName:string,extention:string=this.imageDefaultType,BinaryFileName:string|null=null):string
    {
        if(BinaryFileName!==null && BinaryFileName!=='')
        {
            return this.ImageBinaryLocation(BinaryFileName);
        }
        return this.ImageLocation("/UploadImages/superbases/thumb/",FileName,extention);
    }
    
    
    public static newsGalleryImage(FileName:string, BinaryFileName:string|null =null):string
    {
        if(BinaryFileName!=null && BinaryFileName!=='')
        {
            return this.ImageBinaryLocation(BinaryFileName);
        }
        return this.ImageLocation("/UploadImages/NewsGallery/",FileName,'')
    }
    
    
    private static ImageBinaryLocation(BinrayFileName:string=''):string
    {
        return MAIN_DOMAIN+"/ShowImage.aspx?Id="+BinrayFileName ;
    }
    
    private static ImageLocation(FolderName:string,FileName:string='',FileType:string=this.imageDefaultType):string
    {
        if (FileName!=null && FileName.indexOf(".")!==-1)
        {
            FileType="";
        }
        if(FileName!=='' && FileName!=null)
        {
            if(FileName.toLowerCase().indexOf('http')===0) {
                return FileName ;
            } else {
                return MAIN_DOMAIN+FolderName+FileName+FileType ;
            }
        }
        else
        {
            return '' ;
        }
    }
    
    /**Return the image target of the questionImages or Messages*/
    public static questionImage(FileName:string):string {
        return this.ImageLocation("/UploadImages/TQuestionReply/",FileName,'')
    }
}

export default LocationManager;