import { createAsyncThunk, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IEditProfileFormValues } from 'components/EditProfileForm';
import { showAlert } from 'features/alert/alertSlice';
import globalStorage from 'lib/globalStorage';
import SOAP_request from 'lib/http-request/SOAP-request';
import history from 'lib/router/history';
import globalStorageItems from 'utility/constants/globalStorageItems';
import routes from 'utility/routes';


export interface IUserToken {
    SESSION_ID: string,
    PASSWORD: string
}
export interface IUserInfo {
    AcceptedPhotos: string
    Adress: string
    AllLikeCount: string
    AuthorizedSurveyIds: string
    BirthDate: string
    CityId: string
    CommentCount: string
    ConfirmedPhotoCount: string
    Description: string
    DoCommentOnNewsCount: string
    DoCommentOnPhotoCount: string
    DoLikedCount: string
    EMailAddress: string
    FirstName: string
    GenderTypeBaseId: string
    GenderTypeBaseTitle: string
    GetComment: string
    GetLike: string
    GetView: string
    Grade: string
    IsActive: string
    IsConfirmed: string
    LastName: string
    MobileNo: string
    NewsCount: string
    PhotoName: string
    PostalCode: string
    ReadContentCount: string
    Score: string
    TotalPhotos: string
    TotalRefereeScore: string
    TownId: string
    TownTitle: string
    UserCityId: string
    UserCityTitle: string
    UserCode: string
    UserId: string
    UserName: string
    UserTypeBaseId: string
    WonPhotos: string
}

interface IUserReducerState {
    isLogin: boolean,
    userInfo: IUserInfo | null
}


interface IActivationPayload {
    username: string, 
    password: string,
    rememberMe: boolean
}

const initialState: IUserReducerState = {
    isLogin: false,
    userInfo: null
}

export const login = createAsyncThunk(
    'user/login',
    async ({ username, password, rememberMe }: IActivationPayload, ThunkApi) => {
        const userSessionId = await SOAP_request({
            method: 'POST',
            soapAction: 'Login',
            data: {
                UserName: username,
                Password: password
            }
        })
        .then( res=> res.data )
        .catch( function() {
            ThunkApi.dispatch(
                showAlert({
                    message: 'نام کاربری یا گذرواژه نادرست میباشد',
                    severity: 'error'
                })
            );
            throw new Error('incorrect username or password');
        });

        globalStorage.setItem<IUserToken>(
            globalStorageItems.USER_TOKEN,
            {
              SESSION_ID: userSessionId,
              PASSWORD: password
            }, 
            rememberMe ? undefined : 1 
        );

        await ThunkApi.dispatch( getUserProfile(userSessionId) );

        history.replace(routes.home.returnURL());
    }
)

export const getUserProfile = createAsyncThunk(
    'user/getUserProfile', 
    async (userSessionId: string|null|undefined = globalStorage.getItem<IUserToken>( globalStorageItems.USER_TOKEN )?.SESSION_ID, ThunkApi) => {
        const { data: { VUser: [ userInfo ] } } = await SOAP_request<{ VUser: [IUserInfo] }>({
            method: 'POST',
            soapAction: 'GetUsersProfile',
            data: {
                LanguageBaseId: 11,
                UserId: userSessionId,
                OtherUserId: userSessionId,
            }
        });

        globalStorage.setItem( globalStorageItems.USER_INFO, userInfo );

        return userInfo;
})

export const editUserProfile = createAsyncThunk(
    'user/editUserProfile', 
    async (editedInfo: IEditProfileFormValues, ThunkApi) =>  {
    await SOAP_request({
        soapAction: 'UpdateUser',
        method: 'POST',
        data: editedInfo,
    });

    await ThunkApi.dispatch( getUserProfile() );
})

export const changeUserPassword = createAsyncThunk(
    'user/changeUserPassword', 
    async ({ OldPassword, NewPassword, cancelToken }: any, ThunkApi) =>  {
    await SOAP_request({
        soapAction: 'ChangePassword',
        method: 'POST',
        cancelToken,
        data: { OldPassword, NewPassword }
    });
    
    ThunkApi.dispatch( 
        showAlert({
            message: 'گذرواژه با موفقیت تغییر کرد',
            severity: 'success',
        }) 
    );
})

export const updateUserPhoto = createAsyncThunk(
    'user/updateUserPhoto', 
    async (base64IMG: string, ThunkApi) =>  {
    await SOAP_request({
        soapAction: 'UpdateUserPhoto',
        method: 'POST',
        data: { UserPhoto: base64IMG }
    });

    await ThunkApi.dispatch( getUserProfile() );
})

export const logout = createAsyncThunk(
    'user/logout', 
    () => globalStorage.clear() 
)

const userSlice = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase( getUserProfile.fulfilled, ( state, action: PayloadAction<IUserInfo> )=> {
                state.isLogin = true;
                state.userInfo = action.payload;
            })
            .addCase( logout.fulfilled, ()=> initialState )
    }
);

export default userSlice;